import { LoadingIndicator } from '@cfa/react-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function InitialLoad({ className }) {
  return (
    <div className={className}>
      <h3>Catering Management Tool</h3>
      <LoadingIndicator data-testid="cfa-spinner" size="md" variant="page" />
    </div>
  );
}

InitialLoad.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledInitialLoad = styled(InitialLoad)`
  font-family: ${(props) => props.theme.fontFamily};
  margin-top: 20vh;
  margin-bottom: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default StyledInitialLoad;
