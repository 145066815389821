import { type Reducer, type UnknownAction, createSelector } from '@reduxjs/toolkit';
import { curry, flatten, map } from 'ramda';

import type { Category, ItemGroups, Menu, MenuItem, Method, NutritionItem } from '../types/menu';
import { formatItemName } from '../util/format';

export type State = {
  isLoading: boolean;
  error: {
    menu: Nullable<string>;
    nutrition: Nullable<string>;
  };
  nutrition: NutritionItem[];
  Delivery: Menu;
  Pickup: Menu;
};

export const types = {
  MENU_REQUEST: '[Menu] Request',
  MENU_SUCCESS: '[Menu] Success',
  MENU_FAILURE: '[Menu] Failure',
  NUTRITION_SUCCESS: '[Menu] Nutrition Success',
  NUTRITION_FAILURE: '[Menu] Nutrition Failure',
};

export const actions = {
  getMenu: () => ({ type: types.MENU_REQUEST }),
  menuSuccess: curry((method: Method, menu: Menu) => ({ type: types.MENU_SUCCESS, method, menu })),
  menuFailure: curry((method: Method, error: ReducerError) => ({ type: types.MENU_FAILURE, method, error })),
  nutritionSuccess: (nutrition: NutritionItem[]) => ({
    type: types.NUTRITION_SUCCESS,
    nutrition,
  }),
  nutritionFailure: (error: ReducerError) => ({
    type: types.NUTRITION_FAILURE,
    error,
  }),
};

const initialState: State = {
  isLoading: false,
  error: {
    menu: null,
    nutrition: null,
  },
  nutrition: [],
  Delivery: { categories: [], itemGroups: {} },
  Pickup: { categories: [], itemGroups: {} },
};

const reducer: Reducer<State, UnknownAction> = (state = initialState, action) => {
  switch (action.type) {
    case types.MENU_REQUEST:
      return { ...state, isLoading: true, error: { menu: null, nutrition: null } };

    case types.MENU_SUCCESS: {
      const { method, menu } = action as ReturnType<(typeof actions)['menuSuccess']>;
      return { ...state, isLoading: false, [method]: menu };
    }

    case types.MENU_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: {
          ...state.error,
          menu: (action as ReturnType<(typeof actions)['menuFailure']>).error.toString(),
        },
      };

    case types.NUTRITION_SUCCESS: {
      const { nutrition } = action as ReturnType<(typeof actions)['nutritionSuccess']>;
      return { ...state, nutrition };
    }

    case types.NUTRITION_FAILURE: {
      const { error } = action as ReturnType<(typeof actions)['nutritionFailure']>;
      return { ...state, error: { ...state.error, nutrition: error.toString() } };
    }

    default:
      return state;
  }
};

export default reducer;

export const selectMenu = (state: { menu: State }) => state.menu;

function lookUpItemGroup(itemGroupId: number = 0, itemGroups: ItemGroups) {
  return itemGroups[itemGroupId]?.items || [];
}

function mapMenuItems(items: MenuItem[], itemGroups: ItemGroups): MenuItem[] {
  return flatten<MenuItem>(
    map<MenuItem, MenuItem[]>((item) => {
      let foundItems = lookUpItemGroup(item.itemGroupId, itemGroups || { items: [] });
      if (item.disabled && item.objectType === 'MODIFIER') {
        return [];
      }
      if (
        ((item.itemPrice >= 0.1 ||
          item.objectType === 'ITEM' ||
          item.objectType === 'MODIFIER' ||
          item.objectType === 'MODIFIER_GROUPING') &&
          (item.meal || item?.objectType !== 'ITEM_GROUPING')) ||
        item.tag === 'PREMIUM_SIDES' ||
        item.tag === 'DESSERTS_PKGMEALS'
      ) {
      } else if (item.leadTime) {
        foundItems = foundItems.map((m) => ({
          ...m,
          leadTime: item.leadTime,
        }));
      }

      let menuItems = mapMenuItems(foundItems, itemGroups || {});
      if (
        ((item.itemPrice >= 0.1 ||
          item.objectType === 'ITEM' ||
          item.objectType === 'MODIFIER' ||
          item.objectType === 'MODIFIER_GROUPING') &&
          (item.meal || item.objectType !== 'ITEM_GROUPING')) ||
        item.tag === 'PREMIUM_SIDES' ||
        item.tag === 'DESSERTS_PKGMEALS'
      ) {
        let comboItems: MenuItem[] | undefined;
        let sideItems: MenuItem[] | undefined;
        let dessertItems: MenuItem[] | undefined;
        let selectedSide: MenuItem | undefined;
        let selectedDessert: MenuItem | undefined;
        if (item.meal) {
          comboItems = [...menuItems];

          // TODO this should be resolved from the Menu side
          comboItems.splice(2, 0, comboItems.splice(1, 1)[0]);

          sideItems = [];
          dessertItems = [];

          menuItems = menuItems.reduce((acc, comboItem) => {
            if (comboItem.itemGroupType === 'Side' && comboItem.items && comboItem.items.length) {
              selectedSide = comboItem.items.find((sideItem) => sideItem.default) || comboItem.items[0];
            }

            if (comboItem.itemType === 'DESSERTS_GROUP' && comboItem.items && comboItem.items.length) {
              selectedDessert = comboItem.items[0];
            }

            const comboModifiers = comboItem.items
              ? comboItem.items
                  .map((comboModifier) => ({
                    ...comboModifier,
                    comboTag: comboItem.tag,
                  }))
                  .filter((comboMod) => {
                    if (comboMod.comboTag === 'PREMIUM_SIDES') {
                      sideItems?.push(comboMod);
                      return false;
                    }
                    if (comboMod.comboTag === 'DESSERTS_PKGMEALS') {
                      dessertItems?.push(comboMod);
                      return false;
                    }
                    return true;
                  })
              : [];
            return [...acc, ...comboModifiers];
          }, [] as MenuItem[]);
        }
        return [
          {
            ...item, // top level menu item (ex. chicken sandwich, premium box meal)
            name: formatItemName(item.name),
            items: menuItems, // available modifiers (ex. sauces, dressing, multigrain bun)
            selectedSide, // default premium box meal side (ex. superfood side)
            selectedDessert, // default premium box meal dessert (ex. cookie)
            comboItems, // items that make up a combo meal (ex. sandwich, cookie, chips)
            sideItems, // available sides for a premium box meal (ex. superfood side, fruit cup)
            dessertItems, // available desserts for a premium box meal (ex. cookie, brownie)
          },
        ];
      }

      return menuItems;
    })(items || []),
  );
}

const mapMenuCategories = (categories: Category[] = [], itemGroups: ItemGroups) =>
  map<Category, Category & { items: MenuItem[] }>((cat) => ({
    ...cat,
    items: mapMenuItems(cat.items, itemGroups),
  }))(categories);

export const getCombinedMenu = (method = '', menu: { [method: string]: Menu } = {}) => {
  const selectedMenu = menu[method] || {};
  return mapMenuCategories(selectedMenu.categories, selectedMenu.itemGroups);
};

export const selectNutrition = createSelector(selectMenu, (menu) => menu.nutrition);
