import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from '../Section';
import Icon from '../Icon';

function EmptyCart({ className, message }) {
  return (
    <StyledSection className={className}>
      <Icon>bag</Icon>
      <div className="message">{message}</div>
    </StyledSection>
  );
}

const StyledSection = styled(Section)`
  margin: 100px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledEmptyCart = styled(EmptyCart)`
  fill: ${(props) => props.theme.colors.text};

  & .message {
    font: ${(props) => props.theme.smallTextFont};
    margin: 0;
  }
`;

EmptyCart.propTypes = {
  className: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default StyledEmptyCart;
