import { sort } from 'ramda';

import ooeConstants from '../constants';

export function ensureValueIsInteger(value: any) {
  let valueAsNumber = Number(value);
  if (Number(value) < 0) {
    valueAsNumber = 0;
  }
  return Number(valueAsNumber.toFixed(0));
}

export function exactFavoriteMatches(currentNames: string[], nameToCheck: string) {
  return currentNames.filter((currentName) => currentName.trim() === nameToCheck.trim());
}

export function favoriteNameMatchesList(currentNames: string[], generatedName: string) {
  const sortByNumber = (a: string, b: string) => {
    const numberA = Number(a.match(ooeConstants.FIND_NUMBER_REGEX));
    const numberB = Number(b.match(ooeConstants.FIND_NUMBER_REGEX));
    return numberA - numberB;
  };
  const escapedName = generatedName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const nameRegex = RegExp(`^(${escapedName})( \\d+){0,1}$`);
  return sort(
    sortByNumber,
    currentNames.filter((favName) => favName && favName.match(nameRegex)),
  );
}
