import { Button, TextField } from '@cfa/react-components';
import { Box, Select } from '@cfacorp/cowponents';
import { contains, groupBy } from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import StyledMenuItem from '../../../components/MenuItem/MenuItem';
import ooeConstants from '../../../constants';
import { selectCartWithPrices } from '../../../reducers';
import { isVcaUser } from '../../../reducers/user';
import { notifyBugsnag } from '../../../services/bugsnag';
import type { MenuItem } from '../../../types/menu';
import { debounce } from '../../../util/utils';
import { actions as cartActions } from '../../reducer';
import PromoFreeToggle from '../PromoFreeToggle';

interface Props {
  toggleEditMode: () => void;
  cartItem: ReturnType<typeof selectCartWithPrices>[number];
}

const EditCartItem: React.FC<Props> = ({ cartItem, toggleEditMode }) => {
  const {
    tag,
    quantity,
    sideItems = [],
    selectedSide,
    dessertItems = [],
    selectedDessert,
    id,
    items,
    promoFree,
    freeSauces = 0,
    specialInstructions = '',
  } = cartItem;
  const dispatch = useDispatch();
  const isVca = useSelector(isVcaUser);
  const [isToggledPromoFree, setTogglePromoFree] = useState(promoFree);
  const update = debounce((text: string) => {
    dispatch(cartActions.updateSpecialInstructions(id, text));
  }, 300);
  const sidesObj = groupBy((sideItem) => sideItem.tag, sideItems);
  const dessertsObj = groupBy((dessertItem) => dessertItem.tag, dessertItems);

  const toggleable = (mod: (typeof items)[number]) => {
    const { tag: modTag } = mod;
    return contains(modTag, ooeConstants.TOGGLEABLE_ITEM_TAGS);
  };

  const togglePromoFreeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked === true) {
      setTogglePromoFree(!isToggledPromoFree);
      dispatch(cartActions.makePromoFree(id));
    } else {
      setTogglePromoFree(!isToggledPromoFree);
      dispatch(cartActions.removePromoFree(id));
    }
  };

  const handleSideItemChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      dispatch(cartActions.updateSideItem(id, sidesObj[event.target.value][0]));
    } catch (error) {
      notifyBugsnag('Edit Side Item Failed', {
        context: 'Edit Cart Item',
        info: {
          error,
          sides: sidesObj,
          item: tag,
          eventTargetValue: event?.target?.value ?? 'undefined or null',
        },
      });
    }
  };

  const handleDessertItemChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      dispatch(cartActions.updateDessertItem(id, dessertsObj[event.target.value][0]));
    } catch (error) {
      notifyBugsnag('Edit Dessert Item Failed', {
        context: 'Edit Cart Item',
        info: {
          error,
          desserts: dessertsObj,
          item: tag,
          eventTargetValue: event?.target?.value ?? 'undefined or null',
        },
      });
    }
  };

  const handleAddAnotherClicked = () => {
    dispatch(cartActions.addToCart(cartItem as MenuItem));
    toggleEditMode();
  };

  return (
    <StyledEditCartItem data-testid="cart-item-edit-box">
      {freeSauces > 0 && (
        <Disclaimer className="sauce-disclaimer">{`${freeSauces * quantity} Free Sauce(s)`}</Disclaimer>
      )}
      <div className="mods">
        {items
          .filter((subItem) => subItem.tag !== 'CARAMEL')
          .map((mod) => (
            <StyledMenuItem
              addToCart={(item: MenuItem) =>
                dispatch(cartActions.addModifier(id, item, { comboTag: mod.comboTag }))
              }
              className="menu-item"
              hidePricing
              imageSize="sm"
              item={mod}
              key={mod.tag}
              quantity={mod.quantity}
              recipe={mod.modifierType === 'RECIPE'}
              toggleable={toggleable(mod)}
              updateQuantity={(qty: number) =>
                dispatch(
                  cartActions.updateModifierQuantity(id, mod as CartItemModifier, qty, {
                    comboTag: mod.comboTag,
                  }),
                )
              }
            />
          ))}
      </div>
      <Box>
        {sideItems.length > 0 && (
          <Select
            data-cy={`${tag}-side`}
            onChange={handleSideItemChange}
            value={selectedSide?.tag}
            width="calc(100% - 20px)"
          >
            {sideItems.map((side) => (
              <option data-value={side} key={side.tag} value={side.tag}>
                {side.name}
              </option>
            ))}
          </Select>
        )}
      </Box>
      <Box>
        {dessertItems.length > 0 && (
          <Select
            data-cy={`${tag}-side`}
            onChange={handleDessertItemChange}
            value={selectedDessert?.tag}
            width="calc(100% - 20px)"
          >
            {dessertItems.map((dessert) => (
              <option data-value={dessert} key={dessert.tag} value={dessert.tag}>
                {dessert.name}
              </option>
            ))}
          </Select>
        )}
      </Box>
      {!isVca && (
        <PromoFreeToggle
          id={id}
          isToggledPromoFree={isToggledPromoFree}
          togglePromoFreeHandler={togglePromoFreeHandler}
        />
      )}
      <TextField
        className="special-instructions"
        defaultValue={specialInstructions}
        maxLength={40}
        multiline
        onChange={(e) => update(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        placeholder="Special Instructions"
      />
      <ButtonContainer>
        <Button
          className="add-another"
          color="secondary"
          data-cy={`split-${tag}`}
          fullWidth
          onClick={handleAddAnotherClicked}
          variant="outlined"
        >
          Add Another & Customize
        </Button>
        <Button
          className="delete-item"
          data-cy={`delete-${tag}`}
          fullWidth
          onClick={() => dispatch(cartActions.deleteItem(id))}
          variant="destructive"
        >
          Delete Item
        </Button>
      </ButtonContainer>
    </StyledEditCartItem>
  );
};

const StyledEditCartItem = styled.div`
  margin-top: 20px;
  & .mods {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  & .sauce-info {
    width: 100%;
    text-align: left;
    margin: 5px 12px;
    font-size: 12px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
  }

  & .sauce-disclaimer {
    text-align: center;
    margin-bottom: 15px;
  }

  & .special-instructions {
    height: 60px;
  }
  & .promo-free-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.phone}) {
    flex-direction: column;
  }

  & button {
    margin: 8px;
  }
`;

export default EditCartItem;
