import { Button } from '@cfa/react-components';
import { Box, Heading, Text } from '@cfacorp/cowponents';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Icon from '../../../components/Icon';
import OverlayModal from '../../../components/Modal/OverlayModal';
import {
  actions as orderActions,
  selectAvailableLocations,
  selectDeliveryError,
  selectShowWarningModal,
} from '../../../reducers/order';
import { actions as userActions } from '../../../reducers/user';

const AddressWarningModal: React.FC = () => {
  const dispatch = useDispatch();
  const availableLocations = useSelector(selectAvailableLocations);
  const showModal = useSelector(selectShowWarningModal);
  const deliveryError = useSelector(selectDeliveryError);

  let icon = 'warning';
  let type;
  let heading;
  let message;
  let key;
  if (deliveryError) {
    ({ type, heading, message, key } = deliveryError);
    if (type) {
      icon = type.toLowerCase?.() ?? 'error';
    }
  }
  const changeLocationHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    dispatch(userActions.lookupLocation(e.target.value));
    dispatch(userActions.updateUserLocation(e.target.value));
  };

  const closeModal = () => {
    dispatch(orderActions.closeWarningModal());
  };

  return (
    <StyledAddressWarningModal close={closeModal} isOpen={showModal}>
      <Close onClick={closeModal}>
        <Icon>close</Icon>
      </Close>
      <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" m="3em">
        <Icon>{icon}</Icon>
        <Heading as="h1" fontSize="5" m="1em">
          {heading}
        </Heading>
        <Text color="textColor" data-testid="message">
          {message}
          <br />
          {key !== 'OUT_OF_RANGE' && <span>A valid address is required to submit this order.</span>}
          <br />
          Please update the address.
        </Text>
        <Box>
          {availableLocations.length > 0 && (
            <select
              className="select-location"
              data-testid="select-location"
              defaultValue="DEFAULT"
              onChange={changeLocationHandler}
            >
              <option disabled value="DEFAULT">
                Select Available Location
              </option>
              {availableLocations.map((l) => (
                <option data-testid="address-option" key={l.locationNumber} value={l.locationNumber}>
                  {`${l.name}, ${l.locationNumber}`}
                </option>
              ))}
            </select>
          )}
        </Box>
        <Button color="secondary" data-testid="button-ok" onClick={closeModal} variant="filled">
          Ok
        </Button>
      </Box>
    </StyledAddressWarningModal>
  );
};

export const StyledAddressWarningModal = styled(OverlayModal)`
  text-align: center;
  padding: 20px;
  .select-location {
    padding: 10px;
    border-color: #b1b1b1;
  }
`;

const Close = styled('div')`
  position: absolute;
  top: 0;
  right: 5px;
  & svg {
    margin: 0;
    width: 20px;
    fill: ${(props) => props.theme.colors.outline};
  }
  :hover {
    cursor: pointer;
  }
`;

export default AddressWarningModal;
