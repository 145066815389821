import { throwError } from 'rxjs';
import type { Location } from '../types/location';
import { MenuResponse, NutritionResponse } from '../types/menu';
import Order, { AddressResult, OrderDates, ValidatedDeliveryAddress } from '../types/order';

import ooeConstants from '../constants';
import request from '../util/requestFactory';
import { indexBy } from '../util/utils';

const editExistingOrder = (guestAccessToken: string, order: Partial<Order> & { destination: string }) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${order.id}/resubmit`;

  return request<Order>({
    url,
    method: 'PUT',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: order,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Edit Existing Order',
    },
  });
};

const processMenu = (res: MenuResponse) => {
  const itemGroups = indexBy(res.itemGroups || [], 'itemGroupId', {
    first: true,
  });
  return { ...res, itemGroups };
};

const getMenuFromApi = (location: string, type: string, destination?: string) => {
  if (location == null || type == null) {
    return {
      execute: () => throwError(new Error('location and type are required')),
    };
  }

  const baseUrl = ooeConstants.URL.ORDERS;
  let url;
  url = `${baseUrl}/locations/3.1/${location}/menu/client/${type}`;
  if (destination) {
    url += `/${destination}`;
  }
  const mapper = processMenu;
  return request({
    url,
    mapper,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Menu',
    },
  });
};

const getNutrition = () => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/2.0/menu/client/nutrition`;
  return request({
    url,
    mapper: (res: NutritionResponse) => res.items || [],
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Nutrition',
    },
  });
};

const getOrderDates = (guestAccessToken: string, orderId: string) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${orderId}/dates`;

  return request<OrderDates>({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Order Dates',
    },
  });
};

const initiateOrder = (guestAccessToken: string, order: Partial<Order> & { destination: string }) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0`;
  return request<Order>({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: order,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Initiate Order',
    },
  });
};

const requestPaymentApi = (guestAccessToken: string, orderId: string) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${orderId}/paymentRequest`;
  return request<{}>({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Request Payment Api',
    },
  });
};

const updateOrder = (guestAccessToken: string, order: Partial<Order> & { destination: string }) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${order.id}`;

  return request<Order>({
    url,
    method: 'PUT',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: order,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Update order',
    },
  });
};

const autocompleteAddressFromApi = (address: string, guestAccessToken: string) => {
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const validAddress = encodeURIComponent(address);
  const url = `${baseUrl}/locations/2.0/address/autocomplete?address=${validAddress}`;
  return request<AddressResult[]>({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Autocomplete address',
    },
  });
};

const validateAddressFromApi = (address: { autocompleteResult: any }, guestAccessToken: string) => {
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/2.0/address/validate`;
  return request<ValidatedDeliveryAddress>({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: address,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Validate address',
    },
  });
};

const validateDeliveryRange = (address: ValidatedDeliveryAddress, guestAccessToken: string) => {
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/4.0/catering/delivery`;
  return request<Location[]>({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: address,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Validate delivery range',
    },
  });
};

export {
  autocompleteAddressFromApi,
  editExistingOrder,
  getMenuFromApi,
  getNutrition,
  getOrderDates,
  initiateOrder,
  requestPaymentApi,
  updateOrder,
  validateAddressFromApi,
  validateDeliveryRange,
};
