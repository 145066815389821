import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import Icon from '../../../components/Icon';
import { selectLeadTimeWarning } from '../../selectors';

const LeadTimeWarning: React.FC = () => {
  const { message } = useSelector(selectLeadTimeWarning);
  return (
    <StyledLeadTimeWarning>
      <Icon margin="0 2px 2px 0" width="17px">
        warning
      </Icon>
      <div>{message}</div>
    </StyledLeadTimeWarning>
  );
};

const StyledLeadTimeWarning = styled(Disclaimer)`
  display: flex;
  align-items: center;
  height: 30px;
`;

export default LeadTimeWarning;
