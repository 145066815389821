import { Typography, IconButton, Icon, Drawer } from '@cfa/react-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Close } from '@cfa/system-icons';
import Section from '../Section';
import WebsitePreview from '../../assets/website_preview.png';
import constants from '../../constants';
import Disclaimer from '../Disclaimer/Disclaimer';

const AddNewCard = ({ history, visible }) => {
  const back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <Drawer
      className="add-new-card"
      content={
        <StyledAddNewCard padding="0">
          <StyledAddNewCardHeader>
            <StyledAddNewCardSpacerDiv />
            <Typography color="tertiary" fontWeight="bold" variant="h4">
              {constants.REQUEST_PAYMENT}
            </Typography>
            <IconButton
              aria-label="close"
              color="tertiary"
              data-cy="done"
              onClick={back}
              size="md"
              style={{ margin: '4px' }}
            >
              <Icon icon={Close} />
            </IconButton>
          </StyledAddNewCardHeader>
          <StyledAddNewCardContent>
            <Disclaimer>
              Immediately after their order is placed, the guest will receive an email with a link to a
              website to view their order, and add a form of payment.
            </Disclaimer>
            <br />
            <ul className="reminders">
              <li>
                Remind the guest they will have four hours after receiving the email to enter their payment
                information. If the four hour window passes, they will need to call the Restaurant.
              </li>
              <li>
                If the guest is a Chick-fil-A One member, they will only have to add payment information once
                and can keep the card on file.
              </li>
              <li>The guest will have the ability to create a Chick-fil-A One account on the website.</li>
            </ul>
            <br />
            <Disclaimer className="preview-title">Website Preview</Disclaimer>
            <img alt="Website Preview" className="preview" src={WebsitePreview} />
            <br />
          </StyledAddNewCardContent>
        </StyledAddNewCard>
      }
      onClose={() => history.goBack()}
      open={visible}
      title={constants.REQUEST_PAYMENT}
      width="wide"
    />
  );
};

const StyledAddNewCard = styled(Section)`
  height: 100%;

  & .reminders {
    margin: 0 auto;
  }

  & .reminders li {
    font-size: 12px;
    margin: 10px;
  }

  & .preview {
    border: 1px solid lightgray;
    width: 80%;
    display: block;
    margin: 10px auto;
  }
  & .preview-title {
    text-align: center;
  }
`;

const StyledAddNewCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #004f71;
  height: 64px;
`;

const StyledAddNewCardSpacerDiv = styled.div`
  width: 40px;
  height: 40px;
`;

const StyledAddNewCardContent = styled.div`
  padding: 32px;
  overflow-y: auto;
  height: 100%;
`;

AddNewCard.propTypes = {
  visible: PropTypes.bool,
  history: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ),
};

AddNewCard.defaultProps = {
  history: {},
  visible: false,
};

export default AddNewCard;
