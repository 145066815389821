import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import Section from '../../../components/Section';
import SectionHeader from '../../../components/SectionHeader';

import { selectPromoFreeActive } from '../../../cart/selectors';
import EventSummary from '../../../components/EventSummary/EventSummary';
import OrderSummary from '../../../components/OrderSummary/OrderSummary';
import Totals from '../../../components/Totals/Totals';
import ooeConstants from '../../../constants';
import { selectCartWithPrices, selectTaxAndTotal } from '../../../reducers';
import { selectCateringOccasion } from '../../../reducers/form';
import {
  selectDeliveryAddressForOrder,
  selectDeliveryAddressValid,
  selectDeliveryTip,
  selectDeliveryTipLoading,
  selectDestination,
  selectEditMode,
  selectFormattedSubTotalAmount,
  selectFormattedTaxAmount,
  selectOrderIsLoading,
  selectPaperGoodsRequired,
  selectShowDefaultPaperGoodsMessage,
  selectTotalAmount,
} from '../../../reducers/order';
import { selectLocationContactDetails } from '../../../reducers/user';
import { formatPrice } from '../../../util/format';
import {
  selectDeliveryTipIsDisplayed,
  selectEventSummaryValid,
  selectSpecialInstructionMessage,
} from '../../selectors';

interface Props {
  isInvoicePage?: boolean;
}

const OrderInformation: React.FC<Props> = ({ isInvoicePage = false }) => {
  const eventDetails = useSelector(getFormValues(ooeConstants.GET_FORM_TYPES.DETAILS));
  const guestDetails = useSelector(getFormValues(ooeConstants.GET_FORM_TYPES.GUEST));
  const secondaryContactDetails = useSelector(getFormValues(ooeConstants.GET_FORM_TYPES.SECONDARY_CONTACT));

  const deliveryAddress = useSelector(selectDeliveryAddressForOrder);
  const destination = useSelector(selectDestination);
  const eventSummaryValid = useSelector(selectEventSummaryValid);
  const addressValidated = useSelector(selectDeliveryAddressValid);
  const restaurantContactDetails = useSelector(selectLocationContactDetails);
  const cartItems = useSelector(selectCartWithPrices);
  const subTotal = useSelector(selectFormattedSubTotalAmount);
  const taxAndTotal = useSelector(selectTaxAndTotal);
  const orderLoading = useSelector(selectOrderIsLoading);
  const deliveryTip = useSelector(selectDeliveryTip);
  const deliveryTipIsDisplayed = useSelector(selectDeliveryTipIsDisplayed);
  const deliveryTipLoading = useSelector(selectDeliveryTipLoading);
  const cateringReason = useSelector(selectCateringOccasion);
  const promoFreeActive = useSelector(selectPromoFreeActive);
  const specialInstructionMessage = useSelector(selectSpecialInstructionMessage);
  const isEditMode = useSelector(selectEditMode);
  const paperGoodsRequired = useSelector(selectPaperGoodsRequired);
  const showDefaultPaperGoodsMessage = useSelector(selectShowDefaultPaperGoodsMessage);
  const taxAmount = useSelector(selectFormattedTaxAmount);
  const totalAmount = useSelector(selectTotalAmount);

  const totalAmountPrice = totalAmount !== 0 ? formatPrice(totalAmount) : taxAndTotal;

  return (
    <>
      <Section>
        <SectionHeader>Event Summary</SectionHeader>
        <EventSummary
          addressValidated={addressValidated}
          cateringReason={cateringReason}
          deliveryAddress={deliveryAddress}
          destination={destination}
          eventDetails={eventDetails}
          eventSummaryValid={eventSummaryValid}
          guestDetails={guestDetails}
          isEditMode={isEditMode}
          isInvoicePage={isInvoicePage}
          paperGoodsRequired={paperGoodsRequired}
          restaurantContactDetails={restaurantContactDetails}
          secondaryContactDetails={secondaryContactDetails}
          showDefaultPaperGoodsMessage={showDefaultPaperGoodsMessage}
          specialInstructionMessage={specialInstructionMessage}
          transId={undefined}
        />
      </Section>
      <Section>
        <SectionHeader>Order Summary</SectionHeader>
        <OrderSummary cartItems={cartItems} />
        <Totals
          deliveryTip={deliveryTip}
          deliveryTipIsDisplayed={deliveryTipIsDisplayed}
          deliveryTipLoading={deliveryTipLoading}
          isLoading={orderLoading}
          promoFreeActive={promoFreeActive}
          subTotal={subTotal}
          taxAmount={taxAmount}
          taxAndTotal={totalAmountPrice}
        />
      </Section>
    </>
  );
};

export default OrderInformation;
