import { addMinutes, format, isAfter, isBefore, parse } from 'date-fns';
import { isEmpty, path, pathOr } from 'ramda';

import ooeConstants from '../constants';
import { notifyBugsnag } from '../services/bugsnag';
import { Holiday, HoursOfOperation, OperatingInterval, SpecialEvent } from '../types/location';
import { isObjWithEmptyStrings } from '../util/utils';

export const validateSecondaryContact = (values: {
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  phoneNumber?: Nullable<string>;
}) => {
  const areEmptyValues = isObjWithEmptyStrings(values);
  const errors: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
  } = {};
  if (!isEmpty(values) && !areEmptyValues) {
    if (!values.firstName) {
      errors.firstName = 'Required Field';
    }
    if (!values.lastName) {
      errors.lastName = 'Required Field';
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Required Field';
    } else if (values.phoneNumber && values.phoneNumber.length < 12) {
      errors.phoneNumber = 'Invalid Phone Number';
    }
  }
  return errors;
};

export const normalizeGuestCountField = (value: number | string) => {
  if (typeof value === 'string') {
    // reject empty strings or values that don't start with a digit.
    const trimmedValue = value.trim();
    if (!/^\d/.test(trimmedValue)) {
      return '';
    }
    value = parseInt(trimmedValue, 10);
  }

  if (value > 9999) {
    return 9999;
  }
  if (value <= 0) {
    return '';
  }
  return value;
};

export const validateDetails = (values: {
  time?: Nullable<string>;
  date?: Nullable<Date>;
  guestCount?: Nullable<number>;
}) => {
  const errors: { time?: string; date?: string; guestCount?: string } = {};
  if (!values.time) {
    errors.time = 'Required Field';
  }
  if (!values.date) {
    errors.date = 'Required Field';
  }
  if (values.guestCount && Number.isNaN(Number(values.guestCount))) {
    errors.guestCount = 'Please enter a number';
  }
  return errors;
};

export const isInBlackoutWindow = (
  slotTime: Date,
  endSlotTime: Date,
  blackoutStart: Date,
  blackoutMinutes: number,
) => {
  const blackoutEndTime = addMinutes(blackoutStart, blackoutMinutes);
  return isBefore(slotTime, blackoutEndTime) && isAfter(endSlotTime, blackoutStart);
};

export const wrapComponentFormField = (WrappedField: any) => (props: any) => {
  const { input, ...remainingProps } = props;
  return <WrappedField {...input} {...remainingProps} />;
};

export const getServiceChannelHours = (
  hoursOfOperation: HoursOfOperation,
  specialEvents: SpecialEvent[] = [],
  pickedDate: Date,
) => {
  const date = format(pickedDate, ooeConstants.DATE_TIME_FORMAT.date);
  const dayOfWeek = format(pickedDate, 'EEEE').toLocaleLowerCase();
  const operationType = path<string>([dayOfWeek, 'operationType'], hoursOfOperation);
  const operatingInterval = path<OperatingInterval>([dayOfWeek, 'operatingInterval'], hoursOfOperation);
  const holidays = path<Holiday[]>(['holidays'], hoursOfOperation);
  const holidayDay = holidays?.filter((h) => h.holidayDate === date);

  const specialEventDay = specialEvents?.find((s) => {
    try {
      const sd = parse(s.startDate, ooeConstants.DATE_TIME_FORMAT.date, new Date());
      const ed = parse(s.endDate, ooeConstants.DATE_TIME_FORMAT.date, new Date());
      const isSpecialEvent = !isAfter(sd, pickedDate) && !isBefore(ed, pickedDate);
      return !!isSpecialEvent;
    } catch (error) {
      // log to Bugsnag and treat invalid dates as non-special events
      notifyBugsnag('selectOrderHistory', {
        context: 'Special Events Search Failed',
        info: {
          error,
          specialEvent: s,
        },
      });
      return false;
    }
  });

  let timeSlots: string[] = [];

  if (holidayDay && holidayDay?.length > 0) {
    const [holiday] = holidayDay;
    const { holidayHours } = holiday;
    const { operationType: holidayOperationType, operatingInterval: holidayOperatingInterval } = holidayHours;

    if (holidayOperationType !== 'closed' && holidayOperatingInterval) {
      const { openTime, durationInMinutes } = holidayOperatingInterval;
      const parsedOpenTime = parse(openTime, ooeConstants.DATE_TIME_FORMAT.time, new Date());
      const endTime = addMinutes(parsedOpenTime, durationInMinutes);
      timeSlots = getTimeSlots(parsedOpenTime, endTime);
    }
  } else if (specialEventDay) {
    const { operationType: specialOperationType, operatingInterval: specialOperatingInterval } =
      specialEventDay.specialEventHours;

    if (specialOperationType === 'standardHours' && specialOperatingInterval) {
      const { openTime, durationInMinutes } = specialOperatingInterval;
      const parsedOpenTime = parse(openTime, ooeConstants.DATE_TIME_FORMAT.time, new Date());
      const endTime = addMinutes(parsedOpenTime, durationInMinutes);
      timeSlots = getTimeSlots(parsedOpenTime, endTime);
    }
  } else if (operationType === 'standardHours' && operatingInterval) {
    const { openTime, durationInMinutes } = operatingInterval;
    const parsedOpenTime = parse(openTime, ooeConstants.DATE_TIME_FORMAT.time, new Date());
    const endTime = addMinutes(parsedOpenTime, durationInMinutes);
    const blackoutHours = pathOr(
      { openTime: '00:00', durationInMinutes: 0 },
      [dayOfWeek, 'blackoutHours'],
      hoursOfOperation,
    );
    timeSlots = getTimeSlots(
      parsedOpenTime,
      endTime,
      parse(blackoutHours.openTime, ooeConstants.DATE_TIME_FORMAT.time, new Date()),
      blackoutHours.durationInMinutes,
    );
  } else if (operationType === 'open24Hours') {
    timeSlots = getTimeSlots(
      parse('00:00', ooeConstants.DATE_TIME_FORMAT.time, new Date()),
      parse('23:45', ooeConstants.DATE_TIME_FORMAT.time, new Date()),
    );
  }

  return timeSlots.map((t) => ({ time: t, available: true }));
};

export const getTimeSlots = (
  openTime: Date,
  endTime: Date,
  blackoutStart = parse('00:00', ooeConstants.DATE_TIME_FORMAT.time, new Date()),
  blackoutMinutes = 0,
  nextSlot = 15,
) => {
  const timeSlotsArr = [];
  let currentSlotOpenTime = openTime;
  while (!isAfter(currentSlotOpenTime, endTime)) {
    const currentSlotEndTime = addMinutes(currentSlotOpenTime, nextSlot);
    if (!isInBlackoutWindow(currentSlotOpenTime, currentSlotEndTime, blackoutStart, blackoutMinutes)) {
      try {
        timeSlotsArr.push(format(currentSlotOpenTime, ooeConstants.DATE_TIME_FORMAT.time));
      } catch (error) {
        // adding some logging in the hope that the details will help to
        // find a resolution to an exception happening here
        const errorDetails = {
          error: error instanceof Error ? error.message : error,
          currentSlotOpenTime: currentSlotOpenTime !== undefined ? currentSlotOpenTime : '[UNDEFINED]',
          endTime: endTime !== undefined ? endTime : '[UNDEFINED]',
          blackoutStart: blackoutStart !== undefined ? blackoutStart : '[UNDEFINED]',
          blackoutMinutes,
          nextSlot,
          timeSlotsArr: Array.isArray(timeSlotsArr) ? timeSlotsArr.slice(0, 10) : '[INVALID ARRAY]',
        };
        notifyBugsnag('Date Change', {
          context: 'Timeslot Formatting Error',
          info: {
            error: errorDetails,
          },
        });
        // don't change existing behavior, fail hard just as before so as to avoid
        // potential unintended consequences until we acquire more info on the error
        // and how to best resolve
        throw error;
      }
    }
    currentSlotOpenTime = addMinutes(currentSlotOpenTime, nextSlot);
  }
  return timeSlotsArr;
};
