import { Button, LoadingIndicator } from '@cfa/react-components';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';

import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import Icon from '../../../components/Icon';
import ooeConstants from '../../../constants';
import { actions as orderActions, selectEditMode, selectSubmitOrderLoading } from '../../../reducers/order';
import { selectAllSubmitWarnings, selectSubmitButtonIsDisabled } from '../../selectors';

const SubmitOrderButton: React.FC = () => {
  const dispatch = useDispatch();

  const isDisabled = useSelector(selectSubmitButtonIsDisabled);
  const submitOrderLoading = useSelector(selectSubmitOrderLoading);
  const isEditMode = useSelector(selectEditMode);
  const submitWarnings = useSelector(selectAllSubmitWarnings);
  const { selectedMethod } = useSelector(getFormValues(ooeConstants.GET_FORM_TYPES.PAYMENT_METHOD)) as {
    selectedMethod: string;
  };

  const buttonText = useMemo(() => {
    if (isEditMode) {
      return 'Save Changes and Update Order';
    }
    if (selectedMethod === ooeConstants.REQUEST_PAYMENT) {
      return 'Send Email';
    }
    return 'Submit Order';
  }, [isEditMode, selectedMethod]);

  return (
    <>
      {submitWarnings &&
        submitWarnings.map((warning) => (
          <Disclaimer data-testid="disclamer" key={warning.message}>
            <DisclaimerIcon
              className={undefined}
              height={undefined}
              margin={undefined}
              onClick={undefined}
              width={undefined}
            >
              {warning.type}
            </DisclaimerIcon>
            {warning.message}
          </Disclaimer>
        ))}
      {submitOrderLoading ? (
        <StyledLoadingIndicator data-testid="spinner" size="md" variant="inline" />
      ) : (
        <Button
          color="primary"
          data-cy="order-submit"
          disabled={isDisabled}
          fullWidth
          onClick={() => dispatch(orderActions.submitOrder())}
          style={{ marginBottom: '80px', marginTop: '10px' }}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

const DisclaimerIcon = styled(Icon)`
  height: 15px;
  width: 15px;
  margin: 2px 7px -2px 0;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
`;

export default SubmitOrderButton;
