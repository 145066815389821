import { values } from 'ramda';

import ooeConstants from '../constants';
import theme from '../theme';

export const debounce = (fn: Function, time: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    const functionCall = () => fn.apply(this, args);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const camelToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const maskEmail = (email?: string) => {
  if (typeof email !== 'string') {
    return undefined;
  }

  const mask = '*****';
  try {
    const idx = email.indexOf('@');
    if (idx < 0) {
      return mask;
    }
    if (idx < 4) {
      return `${mask}${email.substr(idx)}`;
    }
    return `${email.substr(0, 1)}${mask}${email.substr(idx - 1, 1)}${email.substr(idx)}`;
  } catch (e) {
    return undefined;
  }
};

export const stripNonPOSSpecialCharacters = (value?: Nullable<string>) =>
  value && value.replace(ooeConstants.POS_INVALID_CHARS_REGEX, '');

export const roundNumber = (num: number, decimalPlaces = 2) =>
  +(Math.round(Number(num + `e+${[decimalPlaces]}`)) + `e-${decimalPlaces}`);

export const specialInstrHasPaperGoods = (specialInstructions = '') =>
  ooeConstants.PAPER_GOODS_MESSAGES.some((option) => specialInstructions?.includes(option));

export const isObjWithEmptyStrings = (obj = {}) => values(obj).every((value) => value === '');

export const indexBy = (items: any[], propertyName: string, { first = false } = {}) => {
  const objectOfIndices: Record<string, (typeof items)[number]> = {};
  items.forEach((item) => {
    const index = item[propertyName];
    if (!first || !objectOfIndices[index]) {
      objectOfIndices[index] = item;
    }
  });
  return objectOfIndices;
};

export const isSmallScreen = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < +theme.phone.replace('px', '');
  }
  return false;
};
