import { type Reducer, type UnknownAction, createSelector } from '@reduxjs/toolkit';
import {
  clone,
  contains,
  curry,
  equals,
  isEmpty,
  isNil,
  length,
  mapObjIndexed,
  pathOr,
  pick,
  values,
} from 'ramda';

import { actions as cartActions, types as cartTypes } from '../cart/reducer';
import ooeConstants from '../constants';
import type { Location } from '../types/location';
import type {
  AddressResult,
  DeliveryTip,
  Order,
  OrderDate,
  OrderDates,
  OrderDeliveryAddress,
  PaperGoodsOptions,
  ValidatedDeliveryAddress,
} from '../types/order';
import {
  addressErrorMessages,
  dateTimeErrorMessages,
  editWarningMessage,
  statusCodes,
  submitWarningErrorMessages,
} from '../util/customerErrorMessages';
import { formatPrice } from '../util/format';
import { stripNonPOSSpecialCharacters } from '../util/utils';
import { types as guestTypes } from './guest';
import { types as userTypes } from './user';

export type State = {
  loading: {
    [key: (typeof keys)[keyof typeof keys]]: boolean;
  };
  error: {
    [key: (typeof keys)[keyof typeof keys]]: Nullable<
      | string
      | {
          key?: string;
          message?: string;
          heading?: string;
          type?: string;
        }
    >;
  };
  orderType?: string;
  status: string;
  destination: string;
  taxAmount: number;
  subTotalAmount: number;
  editOrderId: Nullable<string>;
  availableDates: OrderDate[];
  companyName: string;
  deliveryAddress: OrderDeliveryAddress;
  validatedDeliveryAddress: ValidatedDeliveryAddress;
  lastAddressSentForValidation: string;
  reorder: boolean;
  transactionId?: Nullable<string>;
  availableLocations: Array<{ locationNumber?: string; name?: string }>;
  deliveryTip: DeliveryTip;
  paperGoodsOptions: PaperGoodsOptions;
  id?: string;
  promiseDateTime?: string;
  totalAmount?: number;
  showWarningModal?: boolean;
  externalOrderInfo?: {
    orderId?: string;
    externalOrderSource?: string;
  };
  editOrderSavedDeliveryAddress?: OrderDeliveryAddress;
  editOrderStatus?: string;
  paperGoods?: boolean;
  editOrderClient?: string;
  familyName?: string;
};

export const types = {
  ADD_EXTERNAL_ORDER_ID: '[Order] Add external id',
  CHANGE_DESTINATION: '[Order] Change destination',
  UPDATE_ORDER_SUCCESS: '[Order] Update order success',
  UPDATE_ORDER_FAILURE: '[Order] Update order failure',
  RESET_ORDER_STATE: '[Order] Reset order state',
  SUBMIT_ORDER: '[Order] Submit order',
  SUBMIT_ORDER_SUCCESS: '[Order] Submit order success',
  SUBMIT_ORDER_FAILURE: '[Order] Submit order failure',
  GET_DATES_SUCCESS: '[Order] Get dates success',
  GET_DATES_FAILURE: '[Order] Get dates failure',
  DATE_CHANGED: '[Order] Date changed',
  TIME_CHANGED: '[Order] Time changed',
  GUEST_COUNT_CHANGED: '[Order] Guest Count Changed',
  GET_TIMES_SUCCESS: '[Order] Get times success',
  AUTOCOMPLETE_ADDRESS: '[Order] Autocomplete Address Request',
  AUTOCOMPLETE_ADDRESS_SUCCESS: '[Order] Autocomplete Address Success',
  AUTOCOMPLETE_ADDRESS_FAILURE: '[Order] Autocomplete Address Failure',
  UPDATE_USER_ADDRESS: '[Order] Update User Address',
  VALIDATE_ADDRESS: '[Order] Validate Address',
  VALIDATE_ADDRESS_SUCCESS: '[Order] Validate Address Success',
  VALIDATE_ADDRESS_FAILURE: '[Order] Validate Address Failure',
  DISMISS_ERROR: '[Order] Dismiss Error',
  INITIATE_EDIT_ORDER: '[Order] Initiate Edit Order',
  EXIT_EDIT_ORDER: '[Order] Exit Order Edit Mode',
  UPDATE_PRICING: '[Order] Update Pricing',
  CLOSE_WARNING_MODAL: '[Order] Close Address Warning Modal',
  VALIDATE_DELIVERY_RANGE_SUCCESS: '[Order] Validate Delivery Range Success',
  VALIDATE_DELIVERY_RANGE_FAILURE: '[Order] Validate Delivery Range Failure',
  ADD_DELIVERY_TIP: '[Order] Add Delivery Tip',
  CLEAR_USER_ADDRESS_FIELDS: '[Order] Clear User Address Fields',
  SET_PAPER_GOODS_OPTIONS: 'Set paper goods options',
};

export const keys = {
  UPDATE_ORDER: 'updateOrder',
  VALIDATE_ADDRESS: 'validateAddress',
  SUBMIT_ORDER: 'submitOrder',
  EDIT_ORDER: 'editOrder',
  VALIDATE_DELIVERY_RANGE: 'validateDeliveryRange',
};

export const actions = {
  changeDestination: (destination: string) => ({
    type: types.CHANGE_DESTINATION,
    destination,
  }),
  updateOrderSuccess: (order: Order) => ({
    type: types.UPDATE_ORDER_SUCCESS,
    key: keys.UPDATE_ORDER,
    order,
  }),
  updateOrderFailure: (error: ReducerError) => ({
    type: types.UPDATE_ORDER_FAILURE,
    key: keys.UPDATE_ORDER,
    error,
  }),
  resetOrderState: () => ({ type: types.RESET_ORDER_STATE }),
  submitOrder: () => ({ type: types.SUBMIT_ORDER, key: keys.SUBMIT_ORDER }),
  submitOrderSuccess: curry((order: State, response: Order) => ({
    type: types.SUBMIT_ORDER_SUCCESS,
    key: keys.SUBMIT_ORDER,
    order,
    response,
  })),
  submitOrderFailure: (error: ReducerError) => ({
    type: types.SUBMIT_ORDER_FAILURE,
    key: keys.SUBMIT_ORDER,
    error,
  }),
  dateChanged: (date: string) => ({ type: types.DATE_CHANGED, date }),
  getDatesSuccess: ({ dates }: OrderDates) => ({ type: types.GET_DATES_SUCCESS, dates }),
  getDatesFailure: (error: ReducerError) => ({ type: types.GET_DATES_FAILURE, error }),
  timeChanged: () => ({ type: types.TIME_CHANGED }),
  guestCountChanged: () => ({ type: types.GUEST_COUNT_CHANGED }),
  autocompleteAddress: (addressEnteredInForm: string) => ({
    type: types.AUTOCOMPLETE_ADDRESS,
    key: keys.VALIDATE_ADDRESS,
    addressEnteredInForm,
  }),
  autocompleteAddressSuccess: curry((addressEnteredInForm: string, response: AddressResult[]) => ({
    type: types.AUTOCOMPLETE_ADDRESS_SUCCESS,
    key: keys.VALIDATE_ADDRESS,
    addressEnteredInForm,
    response,
  })),
  autocompleteAddressFailure: (error: ReducerError) => ({
    type: types.AUTOCOMPLETE_ADDRESS_FAILURE,
    key: keys.VALIDATE_ADDRESS,
    error,
  }),

  updateUserAddress: (value: string, field: 'companyName' | keyof ValidatedDeliveryAddress) => ({
    type: types.UPDATE_USER_ADDRESS,
    value,
    field,
  }),
  closeWarningModal: () => ({ type: types.CLOSE_WARNING_MODAL }),
  validateAddressSuccess: curry(
    (addressEnteredInForm: string, response: ValidatedDeliveryAddress & { cfaError?: object }) => ({
      type: types.VALIDATE_ADDRESS_SUCCESS,
      key: keys.VALIDATE_ADDRESS,
      addressEnteredInForm,
      response,
    }),
  ),
  validateAddressFailure: (error: ReducerError) => ({
    type: types.VALIDATE_ADDRESS_FAILURE,
    key: keys.VALIDATE_ADDRESS,
    error,
  }),
  validateDeliveryRangeSuccess: curry((selectedLocation: string, response: Location[]) => ({
    type: types.VALIDATE_DELIVERY_RANGE_SUCCESS,
    key: keys.VALIDATE_DELIVERY_RANGE,
    selectedLocation,
    response,
  })),
  validateDeliveryRangeFailure: (error: ReducerError) => ({
    type: types.VALIDATE_DELIVERY_RANGE_FAILURE,
    key: keys.VALIDATE_DELIVERY_RANGE,
    error,
  }),
  clearUserAddressFields: () => ({ type: types.CLEAR_USER_ADDRESS_FIELDS }),
  addExternalOrderId: (orderId: string, externalOrderSource: string) => ({
    type: types.ADD_EXTERNAL_ORDER_ID,
    orderId,
    externalOrderSource,
  }),
  dismissError: (key: string) => ({ type: types.DISMISS_ERROR, key }),
  initiateEditOrder: (order: Order, guest: GuestDetails | GuestSearchResult, reorder = false) => ({
    type: types.INITIATE_EDIT_ORDER,
    order,
    guest,
    reorder,
  }),
  exitEditOrder: () => ({
    type: types.EXIT_EDIT_ORDER,
  }),
  updatePricing: () => ({ type: types.UPDATE_PRICING }),
  addDeliveryTip: (deliveryTipType = '', deliveryTipValue = '') => ({
    type: types.ADD_DELIVERY_TIP,
    deliveryTipValue,
    deliveryTipType,
  }),
  setPaperGoodsOptions: (option: keyof PaperGoodsOptions, value: boolean, falseAll = false) => ({
    type: types.SET_PAPER_GOODS_OPTIONS,
    option,
    value,
    falseAll,
  }),
};

const initialState: State = {
  loading: {},
  error: { validateAddress: null, validateDeliveryRange: null },
  orderType: 'catering',
  status: 'Create',
  destination: 'Pickup',
  taxAmount: 0.0,
  subTotalAmount: 0.0,
  editOrderId: null,
  availableDates: [],
  companyName: '',
  deliveryAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    state: '',
    zip: '',
  },
  validatedDeliveryAddress: {
    streetNumber: '',
    route: '',
    subpremise: '',
    locality: '',
    administrativeAreaLevelOne: '',
    administrativeAreaLevelTwo: '',
    postalCode: '',
    country: '',
  },
  lastAddressSentForValidation: '',
  reorder: false,
  transactionId: null,
  availableLocations: [],
  deliveryTip: {},
  paperGoodsOptions: {},
};

const reducer: Reducer<State, UnknownAction> = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_DESTINATION: {
      const { destination } = action as ReturnType<(typeof actions)['changeDestination']>;
      // validate if a legit method
      if (contains(destination, ooeConstants.CATERING_METHODS)) {
        const error = {
          ...state.error,
          [keys.VALIDATE_ADDRESS]: null,
          validateDeliveryRange: null,
        };
        if (destination === ooeConstants.PICKUP) {
          return {
            ...state,
            error,
            destination,
            loading: { ...state.loading, updateOrder: true },
          };
        }
        return {
          ...state,
          error,
          destination,
          loading: { ...state.loading, updateOrder: true },
        };
      }
      return state;
    }

    case userTypes.UPDATE_USER_LOCATION: {
      return {
        ...state,
        availableDates: [],
        id: undefined,
        loading: {
          ...state.loading,
          availableDates: true,
          updateOrder: true,
        },
        showWarningModal: false,
      };
    }

    case types.RESET_ORDER_STATE:
      return {
        ...state,
        taxAmount: 0.0,
        subTotalAmount: 0.0,
        loading: { ...state.loading, updateOrder: false },
      };

    case types.DATE_CHANGED:
      return {
        ...state,
        loading: { ...state.loading, [keys.UPDATE_ORDER]: true },
      };

    case cartTypes.DELETE_ITEM:
    case cartTypes.ADD_TO_CART:
    case cartTypes.ADD_MODIFIER:
    case cartTypes.UPDATE_SIDE_ITEM:
    case cartTypes.UPDATE_DESSERT_ITEM:
    case cartTypes.UPDATE_QUANTITY:
    case cartTypes.UPDATE_MODIFIER_QUANTITY:
    case types.SUBMIT_ORDER: {
      const key =
        action.type === types.SUBMIT_ORDER
          ? (action as ReturnType<(typeof actions)['submitOrder']>).key
          : keys.UPDATE_ORDER;
      return {
        ...state,
        loading: { ...state.loading, [key]: true },
      };
    }

    case types.AUTOCOMPLETE_ADDRESS: {
      const { key, addressEnteredInForm } = action as ReturnType<(typeof actions)['autocompleteAddress']>;

      const { lastAddressSentForValidation } = state;
      if (equals(addressEnteredInForm, lastAddressSentForValidation)) {
        return state;
      }

      return {
        ...state,
        loading: { ...state.loading, [key]: true },
      };
    }

    case cartTypes.REMOVE_NON_EXISTENT_ITEMS: {
      const { tags } = action as ReturnType<(typeof cartActions)['removeNonExistentItems']>;
      if (length(tags) > 0) {
        const cartChangedErrorCode = 1421;
        const { type, message } = statusCodes[cartChangedErrorCode];
        return {
          ...state,
          error: { [keys.UPDATE_ORDER]: `${type}: ${message}` },
        };
      }
      return state;
    }

    case types.ADD_DELIVERY_TIP: {
      const { deliveryTipType, deliveryTipValue } = action as ReturnType<(typeof actions)['addDeliveryTip']>;
      return {
        ...state,
        deliveryTip: {
          [deliveryTipType]: +deliveryTipValue,
        },
        loading: { ...state.loading, addDeliveryTip: true },
      };
    }

    case types.UPDATE_ORDER_SUCCESS: {
      const { order } = action as ReturnType<(typeof actions)['updateOrderSuccess']>;
      let { updateOrder } = state.error;
      updateOrder =
        typeof updateOrder === 'string' && contains(ooeConstants.DISMISSIBLE, updateOrder)
          ? updateOrder
          : null;
      return {
        ...state,
        id: order.id,
        taxAmount: order.taxAmount || 0.0,
        subTotalAmount: order.subTotalAmount || 0.0,
        promiseDateTime: order.promiseDateTime,
        loading: {
          ...state.loading,
          updateOrder: false,
          addDeliveryTip: false,
        },
        error: { ...state.error, updateOrder, [keys.SUBMIT_ORDER]: null },
        totalAmount: order.totalAmount,
        deliveryTip: order.deliveryTip || {},
      };
    }

    case types.GET_DATES_SUCCESS:
      return {
        ...state,
        availableDates: (action as ReturnType<(typeof actions)['getDatesSuccess']>).dates,
        loading: { ...state.loading, availableDates: false },
      };

    case types.UPDATE_USER_ADDRESS: {
      const { field, value } = action as ReturnType<(typeof actions)['updateUserAddress']>;
      if (field === 'companyName') {
        return {
          ...state,
          companyName: value,
        };
      }
      return {
        ...state,
        validatedDeliveryAddress: {
          ...state.validatedDeliveryAddress,
          [field]: value,
        },
      };
    }

    case types.AUTOCOMPLETE_ADDRESS_SUCCESS: {
      const { response, addressEnteredInForm } = action as ReturnType<
        (typeof actions)['autocompleteAddressSuccess']
      >;
      if (response.length < 1) {
        const errorCode = 'ERR_NOT_DELIVERABLE';
        return {
          ...state,
          loading: { ...state.loading, validateAddress: false },
          error: {
            ...state.error,
            validateAddress: addressErrorMessages[errorCode],
          },
          lastAddressSentForValidation: addressEnteredInForm,
          showWarningModal: true,
          availableLocations: [],
        };
      }
      return {
        ...state,
        lastAddressSentForValidation: addressEnteredInForm,
      };
    }

    case types.VALIDATE_ADDRESS_SUCCESS: {
      const { response, addressEnteredInForm } = action as ReturnType<
        (typeof actions)['validateAddressSuccess']
      >;
      const { cfaError } = response;
      if (cfaError) {
        const errorCode = 'ERR_NOT_DELIVERABLE';
        return {
          ...state,
          loading: { ...state.loading, validateAddress: false },
          error: {
            ...state.error,
            validateAddress: addressErrorMessages[errorCode],
          },
          lastAddressSentForValidation: addressEnteredInForm,
          showWarningModal: true,
          availableLocations: [],
        };
      }
      if (!cfaError) {
        const validatedDeliveryAddress = mapObjIndexed(
          stripNonPOSSpecialCharacters,
          pick(
            [
              'streetNumber',
              'route',
              'subpremise',
              'locality',
              'administrativeAreaLevelOne',
              'administrativeAreaLevelTwo',
              'postalCode',
              'country',
            ],
            response,
          ),
        );
        return {
          ...state,
          loading: {
            ...state.loading,
            validateAddress: false,
            validateDeliveryRange: true,
          },
          error: { ...state.error, validateAddress: null },
          lastAddressSentForValidation: addressEnteredInForm,
          validatedDeliveryAddress,
          deliveryAddress: {
            addressLine1: `${validatedDeliveryAddress?.streetNumber} ${validatedDeliveryAddress?.route}`,
            addressLine2: validatedDeliveryAddress?.subpremise,
            city: validatedDeliveryAddress?.locality,
            county: validatedDeliveryAddress?.administrativeAreaLevelTwo,
            state: validatedDeliveryAddress?.administrativeAreaLevelOne,
            zip: validatedDeliveryAddress?.postalCode,
          },
        };
      }
      return state;
    }

    case types.VALIDATE_DELIVERY_RANGE_SUCCESS: {
      const { response, selectedLocation } = action as ReturnType<
        (typeof actions)['validateDeliveryRangeSuccess']
      >;

      const errorCode = 'ERR_OUT_OF_RANGE';
      const findLocation = response.filter((location) => location.locationNumber === selectedLocation);

      if (response.length < 1 || findLocation.length < 1) {
        const onlyCateringDelivery = response.filter(
          (location) => location.mobileOrderingSettings?.fulfillmentMethods.cateringDelivery,
        );
        const availableLocations = onlyCateringDelivery.map((location) => ({
          locationNumber: location.locationNumber,
          name: location.name,
        }));

        return {
          ...state,
          loading: {
            ...state.loading,
            validateDeliveryRange: false,
            validateAddress: false,
          },
          error: {
            ...state.error,
            validateDeliveryRange: addressErrorMessages[errorCode],
          },
          showWarningModal: true,
          availableLocations,
        };
      }
      if (response.length >= 1 && findLocation.length >= 1) {
        return {
          ...state,
          loading: {
            ...state.loading,
            validateDeliveryRange: false,
            validateAddress: false,
          },
          error: { ...state.error, validateDeliveryRange: null },
          availableLocations: [],
        };
      }

      return {
        ...state,
        loading: { ...state.loading, validateDeliveryRange: false },
      };
    }
    case types.CLOSE_WARNING_MODAL: {
      return {
        ...state,
        showWarningModal: false,
      };
    }

    case types.EXIT_EDIT_ORDER:
    case types.SUBMIT_ORDER_SUCCESS: {
      return {
        ...initialState,
        transactionId:
          action.type === types.SUBMIT_ORDER_SUCCESS
            ? (action as ReturnType<(typeof actions)['submitOrderSuccess']>).response.transactionId
            : undefined,
      };
    }

    case types.VALIDATE_ADDRESS_FAILURE:
    case types.SUBMIT_ORDER_FAILURE:
    case types.UPDATE_ORDER_FAILURE:
    case types.AUTOCOMPLETE_ADDRESS_FAILURE:
    case types.VALIDATE_DELIVERY_RANGE_FAILURE: {
      const { error, key } = action as
        | ReturnType<(typeof actions)['updateOrderFailure']>
        | ReturnType<(typeof actions)['submitOrderFailure']>
        | ReturnType<(typeof actions)['autocompleteAddressFailure']>
        | ReturnType<(typeof actions)['validateAddressFailure']>
        | ReturnType<(typeof actions)['validateDeliveryRangeFailure']>;
      const { destination } = state;
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: false,
          availableDates: false,
        },
        error: {
          ...state.error,
          [key]: error.toString().replace('{DESTINATION}', destination.toLowerCase()),
        },
      };
    }

    case types.CLEAR_USER_ADDRESS_FIELDS: {
      return {
        ...state,
        validatedDeliveryAddress: {
          ...initialState.validatedDeliveryAddress,
        },
        deliveryAddress: {
          ...initialState.deliveryAddress,
        },
        companyName: initialState.companyName,
        error: {
          ...state.error,
          validateAddress: null,
          validateDeliveryRange: null,
        },
      };
    }

    case types.GET_DATES_FAILURE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          availableDates: false,
        },
        error: {
          ...state.error,
          availableDates: dateTimeErrorMessages.DATES_FAILURE,
        },
      };
    }

    case types.ADD_EXTERNAL_ORDER_ID: {
      const { orderId, externalOrderSource } = action as ReturnType<(typeof actions)['addExternalOrderId']>;
      return {
        ...state,
        externalOrderInfo: {
          orderId,
          externalOrderSource,
        },
      };
    }

    case types.DISMISS_ERROR: {
      const newState = clone(state);
      Object.keys(newState.error).forEach((errorKey) => {
        if (
          typeof newState.error[errorKey] === 'string' &&
          contains(ooeConstants.DISMISSIBLE, newState.error[errorKey])
        ) {
          delete newState.error[errorKey];
        }
      });
      return newState;
    }

    case types.SET_PAPER_GOODS_OPTIONS: {
      const { option, value, falseAll } = action as ReturnType<(typeof actions)['setPaperGoodsOptions']>;
      if (falseAll) {
        return {
          ...state,
          paperGoodsOptions: {
            plates: false,
            cutlery: false,
            napkins: false,
            cups: false,
          },
          paperGoods: false,
        };
      }
      if (option === 'plates') {
        return {
          ...state,
          paperGoodsOptions: {
            ...state.paperGoodsOptions,
            plates: value,
          },
          paperGoods:
            value ||
            state.paperGoodsOptions.cutlery ||
            state.paperGoodsOptions.napkins ||
            state.paperGoodsOptions.cups,
        };
      }
      if (option === 'cutlery') {
        return {
          ...state,
          paperGoodsOptions: {
            ...state.paperGoodsOptions,
            cutlery: value,
          },
          paperGoods:
            state.paperGoodsOptions.plates ||
            value ||
            state.paperGoodsOptions.napkins ||
            state.paperGoodsOptions.cups,
        };
      }
      if (option === 'napkins') {
        return {
          ...state,
          paperGoodsOptions: {
            ...state.paperGoodsOptions,
            napkins: value,
          },
          paperGoods:
            state.paperGoodsOptions.plates ||
            state.paperGoodsOptions.cutlery ||
            value ||
            state.paperGoodsOptions.cups,
        };
      }
      if (option === 'cups') {
        return {
          ...state,
          paperGoodsOptions: {
            ...state.paperGoodsOptions,
            cups: value,
          },
          paperGoods:
            state.paperGoodsOptions.plates ||
            state.paperGoodsOptions.cutlery ||
            state.paperGoodsOptions.napkins ||
            value,
        };
      }
      return state;
    }

    case types.INITIATE_EDIT_ORDER: {
      const { order, guest, reorder } = action as ReturnType<(typeof actions)['initiateEditOrder']>;
      const { first, last } = guest as GuestDetails;
      const { firstName, lastName } = guest as GuestSearchResult;
      const { id, deliveryTip, paperGoods, clientId } = order;
      let editOrderId: Nullable<string> = id;
      let { destination, deliveryAddress, companyName } = order;
      const { status } = order;
      if (!destination) {
        destination = 'Pickup';
      }
      if (!deliveryAddress) {
        deliveryAddress = {
          addressLine1: '',
          addressLine2: '',
          city: '',
          county: '',
          state: '',
          zip: '',
        };
      }
      if (!companyName) {
        companyName = '';
      }
      let error = {
        [keys.EDIT_ORDER]: `${editWarningMessage} ${first || firstName} ${last || lastName} ${ooeConstants.DISMISSIBLE}`,
      };
      if (reorder) {
        editOrderId = null;
        error = {};
      }
      return {
        ...state,
        destination,
        companyName,
        deliveryAddress,
        editOrderSavedDeliveryAddress: deliveryAddress,
        editOrderId,
        editOrderStatus: status,
        loading: { ...state.loading, [keys.UPDATE_ORDER]: true },
        error,
        reorder,
        deliveryTip: deliveryTip || {},
        paperGoods,
        paperGoodsOptions: {},
        editOrderClient: clientId,
      };
    }

    case guestTypes.MASQUERADE_GUEST_UNSELECTED: {
      return {
        ...state,
        reorder: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;

// Order selectors
export const selectOrder = (state: { order: State }) => state.order;
export const selectOrderForDXE = createSelector(selectOrder, (order) => {
  const orderPropsForDXE = [
    'orderType',
    'status',
    'destination',
    'companyName',
    'deliveryAddress',
    'id',
    'promiseDateTime',
    'paperGoods',
    'specialInstructions',
    'businessDate',
    'locationNumber',
    'externalOrderInfo',
  ];
  const cleanedUpOrder = pick(orderPropsForDXE, order);

  if (!isNil(order.editOrderId)) {
    return {
      ...cleanedUpOrder,
      id: order.editOrderId,
    };
  }

  return cleanedUpOrder;
});

export const selectTransactionId = createSelector(selectOrder, (order) => order.transactionId);

// Destination and Delivery selectors
export const selectLastAddressSentForValidation = createSelector(
  selectOrder,
  (order) => order.lastAddressSentForValidation,
);
export const selectDestination = createSelector(selectOrder, (order) => order.destination);
export const selectDeliveryAddress = createSelector(selectOrder, (order) => ({
  ...order.validatedDeliveryAddress,
  companyName: order.companyName,
}));
export const selectDeliveryAddressForOrder = createSelector(selectOrder, (order) => ({
  ...order.deliveryAddress,
  companyName: order.companyName,
}));
export const selectEditOrderSavedDeliveryAddress = createSelector(selectOrder, (order) => ({
  ...order.editOrderSavedDeliveryAddress,
  companyName: order.companyName,
}));
export const selectDeliveryAddressValid = createSelector(selectDeliveryAddress, (address) =>
  Boolean(
    address.streetNumber &&
      address.route &&
      address.locality &&
      address.administrativeAreaLevelOne &&
      address.postalCode,
  ),
);
export const selectDeliveryAddressChanged = createSelector(
  selectDeliveryAddressForOrder,
  selectEditOrderSavedDeliveryAddress,
  (currentAddress, savedAddress) => !equals(currentAddress, savedAddress),
);
export const selectDestinationValid = createSelector(
  selectDeliveryAddressValid,
  selectDestination,
  (validated, destination) => {
    let valid;
    if (destination === ooeConstants.PICKUP) {
      valid = true;
    }
    if (destination === ooeConstants.DELIVERY) {
      valid = validated;
    }
    return valid;
  },
);
export const selectShowWarningModal = createSelector(selectOrder, (order) => order.showWarningModal);

export const selectAvailableLocations = createSelector(selectOrder, (order) => order.availableLocations);

export const selectApartmentSuite = createSelector(
  selectOrder,
  (order) => order.validatedDeliveryAddress?.subpremise,
);

// Tax and Total selectors
export const selectTaxAmount = createSelector(selectOrder, (order) => order.taxAmount ?? 0);

export const selectSubTotalAmount = createSelector(selectOrder, (order) => order.subTotalAmount ?? 0);

export const selectTotalAmount = createSelector(selectOrder, (order) => order.totalAmount ?? 0);

export const selectFormattedSubTotalAmount = createSelector(selectOrder, (order) =>
  formatPrice(order.subTotalAmount),
);

export const selectFormattedTaxAmount = createSelector(selectTaxAmount, (taxAmount) =>
  formatPrice(taxAmount),
);

// Date and Time selectors
export const selectAvailableDates = createSelector(selectOrder, (order) => order.availableDates);

// Loading selectors
export const selectOrderIsLoading = createSelector(selectOrder, (order) => order.loading.updateOrder);
export const selectSubmitOrderLoading = createSelector(selectOrder, (order) => order.loading.submitOrder);
export const selectValidateAddressLoading = createSelector(
  selectOrder,
  (order) => order.loading.validateAddress,
);
export const selectValidateDeliveryRangeLoading = createSelector(
  selectOrder,
  (order) => order.loading.validateDeliveryRange,
);

export const selectDatesLoading = createSelector(selectOrder, (order) => order.loading.availableDates);

// Error selectors
export const selectOrderError = createSelector(selectOrder, (order) => order.error);
export const selectSubmitOrderError = createSelector(selectOrderError, (error) => error.submitOrder);
export const selectDeliveryError = createSelector(
  selectOrderError,
  (error) =>
    (error.validateAddress || error.validateDeliveryRange) as Nullable<
      (typeof addressErrorMessages)[keyof typeof addressErrorMessages]
    >,
);

export const selectValidAddressError = createSelector(selectOrderError, (error) => error.validateAddress);
export const selectDeliveryRangeWarning = createSelector(
  selectOrderError,
  (error) => error.validateDeliveryRange,
);
export const selectUpdateOrderError = createSelector(selectOrderError, (error) => error.updateOrder);
export const selectOrderErrors = createSelector(selectOrderError, (error) =>
  values(pick([keys.UPDATE_ORDER, keys.SUBMIT_ORDER], error)),
);
export const selectOrderUpdateErrors = createSelector(selectOrderError, (error) =>
  values(pick([keys.UPDATE_ORDER], error)),
);
export const selectHasOrderUpdateErrors = createSelector(
  selectOrderErrors,
  (orderErrors) => orderErrors.filter((error) => Boolean(error)).length > 0,
);
export const selectDeliveryAddressSubmitWarning = createSelector(
  selectDestination,
  selectDeliveryAddressValid,
  selectValidAddressError,
  selectDeliveryRangeWarning,
  (destination, allFieldsFilled, deliveryError, deliveryWarning) => {
    if (destination === ooeConstants.DELIVERY && !allFieldsFilled) {
      return {
        type: 'error',
        message: submitWarningErrorMessages.MISSING_FIELDS,
      };
    }
    if (destination === ooeConstants.DELIVERY && !isNil(deliveryError)) {
      return {
        type: 'error',
        message: submitWarningErrorMessages.ADDRESS_WARNING,
      };
    }
    if (destination === ooeConstants.DELIVERY && !isNil(deliveryWarning)) {
      return {
        type: 'warning',
        message: submitWarningErrorMessages.DELIVERY_RANGE,
      };
    }
    return {};
  },
);

export const selectDeliveryTip = createSelector(selectOrder, (order) => order.deliveryTip);

export const selectDeliveryTipLoading = createSelector(selectOrder, (order) => order.loading.addDeliveryTip);

export const selectDeliveryTipAmount = createSelector(
  selectDeliveryTip,
  (deliveryTip) => deliveryTip?.tipAmount ?? 0,
);

export const selectSelectedPercentageTipValue = createSelector(
  selectDeliveryTip,
  (deliveryTip) => deliveryTip?.tipPercentOfSale ?? 0,
);

export const selectSelectedCustomTipValue = createSelector(
  selectDeliveryTipAmount,
  selectSelectedPercentageTipValue,
  (tipAmount, tipPercentOfSale) => {
    let customValue = 0;
    if (tipAmount && !tipPercentOfSale) {
      customValue = tipAmount;
    }
    return customValue;
  },
);

// Edit Order selectors
export const selectEditOrderId = createSelector(selectOrder, (order) => order.editOrderId);
export const selectEditMode = createSelector(selectOrder, (order) => !isNil(order.editOrderId));
export const selectReorder = createSelector(selectOrder, (order) => order.reorder);
export const selectHideNewPaymentOption = createSelector(selectEditMode, selectOrder, (editMode, order) => {
  const { editOrderStatus } = order;
  return editMode && editOrderStatus === 'Submit';
});

//Paper Goods Selectors
export const selectPaperGoodsOptions = createSelector(selectOrder, (order) => order.paperGoodsOptions);

export const selectPaperGoodsYesOrNo = createSelector(selectPaperGoodsOptions, (options) => {
  if (!isEmpty(options) && Object.values(options).some((opt) => opt === true)) {
    return 'yes';
  }
  if (!isEmpty(options) && Object.values(options).every((opt) => opt === false)) {
    return 'no';
  }
  return '';
});

export const selectPaperGoodsError = createSelector(
  selectPaperGoodsOptions,
  selectEditMode,
  (options, isEditMode) => (!isEditMode && isEmpty(options) ? ['Paper Goods'] : []),
);

export const selectPaperGoodsRequired = createSelector(
  selectPaperGoodsOptions,
  selectEditMode,
  (options, isEditMode) => !isEditMode && isEmpty(options),
);

export const selectIsEditCMTOrder = createSelector(
  selectOrder,
  (order) =>
    pathOr('', ['editOrderClient'], order) === 'ooe_masquerade' ||
    pathOr('', ['editOrderClient'], order) === 'MyPayments',
);

export const selectShowDefaultPaperGoodsMessage = createSelector(
  selectEditMode,
  selectIsEditCMTOrder,
  selectPaperGoodsOptions,
  (isEdit, isCMT, paperGoodsOptions) => !!(isEdit && !isCMT && isEmpty(paperGoodsOptions)),
);

export const selectPaperGoodsBool = createSelector(selectOrder, (order) => !!order.paperGoods);
