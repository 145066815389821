import { Box, Flex } from '@cfacorp/cowponents';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import Icon from '../../../components/Icon';
import { actions as orderActions, selectDeliveryAddress } from '../../../reducers/order';
import { selectDeliveryRange } from '../../../reducers/user';
import ValidationMessage from '../ValidationMessage';

const DeliveryDestinationFormHeader: React.FC = () => {
  const dispatch = useDispatch();
  const deliveryRange = useSelector(selectDeliveryRange);
  const { streetNumber, route, subpremise, locality, postalCode, companyName, administrativeAreaLevelOne } =
    useSelector(selectDeliveryAddress);
  const clearButtonIsShown =
    streetNumber !== '' ||
    route !== '' ||
    subpremise !== '' ||
    locality !== '' ||
    administrativeAreaLevelOne !== '' ||
    postalCode !== '' ||
    companyName !== '';

  return (
    <StyledDeliveryDestinationFormHeader width={[1]}>
      <Flex alignItems="bottom" justifyContent="space-between">
        <div className="text-box">
          <ValidationMessage />
          <Disclaimer style={{ paddingLeft: '22px' }}>{`Delivery Range: ${deliveryRange}mi`}</Disclaimer>
        </div>
        {clearButtonIsShown && (
          <Close className="clear" onClick={() => dispatch(orderActions.clearUserAddressFields())}>
            <Icon>close</Icon>
          </Close>
        )}
      </Flex>
    </StyledDeliveryDestinationFormHeader>
  );
};

export const Close = styled('div')`
  margin-right: 10px;
  & svg {
    margin: 0;
    width: 25px;
    height: 25px;
    fill: ${(props) => props.theme.colors.outline};
  }
  :hover {
    cursor: pointer;
  }
`;

const StyledDeliveryDestinationFormHeader = styled(Box)`
  & .text-box {
    @media (max-width: ${(props) => props.theme.phone}) {
      max-width: calc(100% - 25px);
    }
    & svg {
      min-width: 17px;
    }
  }
  & .message {
    line-height: 1;
    @media (max-width: ${(props) => props.theme.phone}) {
      padding-bottom: 5px;
    }
  }
`;

export default DeliveryDestinationFormHeader;
