import React from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { formatPrice, formatRestaurantLocalTime } from '../../../util/format';
import {
  selectDeliveryRange,
  selectLocationContactDetails,
  selectLocationTimezone,
} from '../../../reducers/user';
import WorkingHours from '../WorkingHours';
import {
  selectStoreInfoSpecialMessage,
  selectAllDayChickenMinis,
  selectCateringDeliveryHoursOfOperation,
  selectDeliveryLeadTimeStoreInfo,
  selectInStateDeliveryOnly,
  selectMaxDeliveryAmount,
  selectMaxPickupAmount,
  selectMaxSameDayOrderTotalAmountCents,
  selectMinDeliveryAmount,
  selectMinPickupAmount,
  selectPickupLeadTimeStoreInfo,
  selectRestaurantHoursOfOperation,
} from '../../selectors';

const StoreInformation: React.FC = () => {
  const pickupLeadTime = useSelector(selectPickupLeadTimeStoreInfo) ?? 0;
  const deliveryLeadTime = useSelector(selectDeliveryLeadTimeStoreInfo) ?? 0;
  const deliveryRange = useSelector(selectDeliveryRange) ?? 0;
  const minDeliveryAmount = useSelector(selectMinDeliveryAmount) ?? 0;
  const minPickupAmount = useSelector(selectMinPickupAmount) ?? 0;
  const maxDeliveryAmount = useSelector(selectMaxDeliveryAmount) ?? 0;
  const maxPickupAmount = useSelector(selectMaxPickupAmount) ?? 0;
  const maxSameDayOrderTotalAmountCents = useSelector(selectMaxSameDayOrderTotalAmountCents) ?? 0;
  const inStateDeliveryOnly = useSelector(selectInStateDeliveryOnly) ?? false;
  const allDayChickenMinis = useSelector(selectAllDayChickenMinis);
  const storeInfoSpecialMessage = useSelector(selectStoreInfoSpecialMessage);
  const restaurantWorkingHours = useSelector(selectRestaurantHoursOfOperation);
  const cateringDeliveryWorkingHours = useSelector(selectCateringDeliveryHoursOfOperation);
  const locationTimezone = useSelector(selectLocationTimezone);
  const { restaurantPhone } = useSelector(selectLocationContactDetails);

  let minTotalAmount;
  let maxTotalAmount;
  let maxFutureDayCount;

  if (minDeliveryAmount === minPickupAmount) {
    minTotalAmount = (
      <p className="minTotalAmount">
        Minimum total amount: <span>{formatPrice(minDeliveryAmount / 100)}</span>
      </p>
    );
  } else {
    minTotalAmount = (
      <>
        {minDeliveryAmount !== 0 ? (
          <p className="minDeliveryAmount">
            Minimum delivery total amount: <span>{formatPrice(minDeliveryAmount / 100)}</span>
          </p>
        ) : (
          ''
        )}
        {minPickupAmount !== 0 ? (
          <p className="minPickupAmount">
            Minimum pickup total amount: <span>{formatPrice(minPickupAmount / 100)}</span>
          </p>
        ) : (
          ''
        )}
      </>
    );
  }

  if (maxDeliveryAmount === maxPickupAmount) {
    maxTotalAmount = (
      <p className="maxTotalAmount">
        Maximum total amount: <span>{formatPrice(maxDeliveryAmount / 100)}</span>
      </p>
    );
  } else {
    maxTotalAmount = (
      <>
        {maxDeliveryAmount !== 0 ? (
          <p className="maxDeliveryAmount">
            Maximum delivery total amount: <span>{formatPrice(maxDeliveryAmount / 100)}</span>
          </p>
        ) : (
          ''
        )}
        {maxPickupAmount !== 0 ? (
          <p className="maxPickupAmount">
            Maximum pickup total amount: <span>{formatPrice(maxPickupAmount / 100)}</span>
          </p>
        ) : (
          ''
        )}
      </>
    );
  }
  return (
    <StyledStoreInformation className="styled-store-info">
      {(restaurantWorkingHours.length !== 0 || cateringDeliveryWorkingHours.length !== 0) && (
        <WorkingHours
          cateringDeliveryWorkingHours={cateringDeliveryWorkingHours}
          restaurantWorkingHours={restaurantWorkingHours}
        />
      )}
      {locationTimezone !== '' ? (
        <p className="localTime">
          Local Time: <span>{formatRestaurantLocalTime(locationTimezone)}</span>
        </p>
      ) : (
        ''
      )}
      {deliveryLeadTime !== 0 ? (
        <p className="deliveryLead">
          Delivery lead time: <span>{deliveryLeadTime / 60}hr</span>
        </p>
      ) : (
        ''
      )}
      {pickupLeadTime !== 0 ? (
        <p className="pickupLead">
          Pickup lead time: <span>{pickupLeadTime / 60}hr</span>
        </p>
      ) : (
        ''
      )}
      {deliveryRange !== 0 ? (
        <p className="deliveryRange">
          Delivery Range: <span>{deliveryRange}mi</span>
        </p>
      ) : (
        ''
      )}
      {minTotalAmount}
      {maxTotalAmount}
      {maxSameDayOrderTotalAmountCents !== 0 ? (
        <p className="maxSameDayOrderTotalAmountCents">
          Maximum same day amount: <span>{formatPrice(maxSameDayOrderTotalAmountCents / 100)}</span>
        </p>
      ) : (
        ''
      )}
      {maxFutureDayCount}
      {inStateDeliveryOnly && <p className="inStateDeliveryOnly">In state delivery only!</p>}
      {restaurantPhone !== '' && (
        <p className="restaurantPhone">
          Restaurant Phone: <a href={`tel:${restaurantPhone}`}>{restaurantPhone}</a>
        </p>
      )}
      {allDayChickenMinis !== '' && <p className="allDayChichenMinis">{allDayChickenMinis}</p>}
      {storeInfoSpecialMessage !== '' && <p className="storeInfoSpecialMessage">{storeInfoSpecialMessage}</p>}
    </StyledStoreInformation>
  );
};

export const StyledStoreInformation = styled('div')`
  background-color: ${(props) => props.theme.colors.secondary};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  & p {
    margin: 3px 0;
  }
  & span {
    font: ${(props) => props.theme.regularBoldFont};
  }
  & .restaurantPhone a {
    text-decoration: none;
    font: ${(props) => props.theme.regularBoldFont};
    color: ${(props) => props.theme.colors.text};
  }
`;

export default StoreInformation;
