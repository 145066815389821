import { find, isEmpty, propEq } from 'ramda';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, take } from 'rxjs/operators';

import {
  addToFavorites,
  authenticateOnGuestBehalf,
  getFavoriteOrders,
  getGuestEmailSearchResultsFromApi,
  getGuestPastOrders,
  getGuestPhoneSearchResultsFromApi,
  getGuestSearchResultsFromApi,
  getPaymentFromApi,
  initiateGuestSession,
  lookupPaymentByAccountId,
  refreshToken,
  removeFromFavorites,
  updateFavoriteName,
  validateZipFromApi,
} from '../apis';
import ooeConstants from '../constants';
import type { State } from '../reducers';
import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectPhoneFormForApi,
  selectValidEmail,
  selectValidPhone,
} from '../reducers/form';
import {
  actions as guestActions,
  types as guestTypes,
  keys,
  selectApiKey,
  selectPastOrdersLength,
  selectRefreshToken,
  selectTokenType,
  selectVaultedCards,
} from '../reducers/guest';
import { actions as orderActions, types as orderTypes, selectDestination } from '../reducers/order';
import {
  selectAccessToken,
  selectLocationNumber,
  selectUserHasAgreedToEula,
  actions as userActions,
  types as userTypes,
} from '../reducers/user';
import { leaveBreadcrumb, startSession } from '../services/bugsnag';
import type { OrderPayment } from '../types/order';
import epicHelper, { epicOptions } from '../util/myEpicHelper';

export const InitiateGuestSession: Epic<
  | ReturnType<(typeof guestActions)['clearGuestState']>
  | ReturnType<(typeof orderActions)['exitEditOrder']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['messageAcknowledgedSuccess']>
  | ReturnType<(typeof userActions)['addUserToAcknowledgementSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof guestActions)['guestSessionSuccess']>
  | ReturnType<(typeof guestActions)['guestSessionFailure']>,
  | ReturnType<(typeof guestActions)['clearGuestState']>
  | ReturnType<(typeof orderActions)['exitEditOrder']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['messageAcknowledgedSuccess']>
  | ReturnType<(typeof userActions)['addUserToAcknowledgementSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof guestActions)['guestSessionSuccess']>
  | ReturnType<(typeof guestActions)['guestSessionFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(
      guestTypes.CLEAR_GUEST_STATE,
      orderTypes.EXIT_EDIT_ORDER,
      userTypes.REFRESH_TOKEN_SUCCESS,
      userTypes.PROCESS_OKTA_TOKEN_SUCCESS,
      userTypes.MESSAGE_ACKNOWLEDGED_SUCCESS,
      userTypes.ADD_USER_TO_ACKNOWLEDGEMENT_SUCCESS,
    ),
    filter(() => {
      const state = store.value;
      return window.Cypress || selectUserHasAgreedToEula(state);
    }),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);

      startSession();

      return epicHelper(
        initiateGuestSession(accessToken),
        guestActions.guestSessionSuccess,
        guestActions.guestSessionFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const RefreshToken: Epic<
  | ReturnType<(typeof guestActions)['refreshToken']>
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['masqueradeSessionFailure']>
  | ReturnType<(typeof guestActions)['guestSessionSuccess']>
  | ReturnType<(typeof guestActions)['guestSessionFailure']>,
  | ReturnType<(typeof guestActions)['refreshToken']>
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['masqueradeSessionFailure']>
  | ReturnType<(typeof guestActions)['guestSessionSuccess']>
  | ReturnType<(typeof guestActions)['guestSessionFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.REFRESH_TOKEN),
    switchMap(() => {
      const state = store.value;
      const guestRefreshToken = selectRefreshToken(state) as string;
      const tokenType = selectTokenType(state);
      let successAction;
      let failureAction;
      if (tokenType === ooeConstants.TOKEN_TYPES.GUEST_TYPE) {
        successAction = guestActions.guestSessionSuccess;
        failureAction = guestActions.guestSessionFailure;
      } else {
        successAction = guestActions.masqueradeSessionSuccess;
        failureAction = guestActions.masqueradeSessionFailure;
      }

      leaveBreadcrumb('Refresh Token', {
        message: 'Calling refreshToken',
      });

      return refreshToken(guestRefreshToken)
        .execute()
        .pipe(
          map((res) => successAction(res)),
          catchError((err) => of(failureAction(err.toString()))),
        );
    }),
  );

export const AuthenticateOnGuestBehalf: Epic<
  | ReturnType<(typeof guestActions)['guestSelected']>
  | ReturnType<(typeof orderActions)['initiateEditOrder']>
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['masqueradeSessionFailure']>,
  | ReturnType<(typeof guestActions)['guestSelected']>
  | ReturnType<(typeof orderActions)['initiateEditOrder']>
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['masqueradeSessionFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.GUEST_SELECTED, orderTypes.INITIATE_EDIT_ORDER),
    filter((action) => {
      const { guest } = action as
        | ReturnType<(typeof guestActions)['guestSelected']>
        | ReturnType<(typeof orderActions)['initiateEditOrder']>;
      const { reorder } = action as ReturnType<(typeof orderActions)['initiateEditOrder']>;
      return !isEmpty(guest) && reorder !== true;
    }),
    switchMap((action) => {
      const { guest } = action as
        | ReturnType<(typeof guestActions)['guestSelected']>
        | ReturnType<(typeof orderActions)['initiateEditOrder']>;
      const { cfaId } = guest;
      const state = store.value;
      const accessToken = selectAccessToken(state);

      leaveBreadcrumb('Authenticate On Guest Behalf', {
        message: 'Calling authenticateOnGuestBehalf',
        cfaId,
      });

      return epicHelper(
        authenticateOnGuestBehalf(cfaId, accessToken),
        guestActions.masqueradeSessionSuccess,
        guestActions.masqueradeSessionFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetGuestSearchResults: Epic<
  | ReturnType<(typeof guestActions)['guestSearch']>
  | ReturnType<(typeof guestActions)['guestSearchSuccess']>
  | ReturnType<(typeof guestActions)['guestSearchFailure']>,
  | ReturnType<(typeof guestActions)['guestSearch']>
  | ReturnType<(typeof guestActions)['guestSearchSuccess']>
  | ReturnType<(typeof guestActions)['guestSearchFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.GUEST_SEARCH_REQUEST),
    debounceTime(500),
    switchMap(() => {
      const state = store.value;
      const locationNumber = selectLocationNumber(state);
      const searchedFirstName = selectFirstName(state) || '';
      const searchedLastName = selectLastName(state) || '';
      const accessToken = selectAccessToken(state);

      if (searchedFirstName.length === 0 && searchedLastName.length === 0) {
        return of(guestActions.guestSearchSuccess([]));
      }

      const cfaOneOnly = true;

      leaveBreadcrumb('Get Guest Search Results From Api', {
        message: 'Calling getGuestSearchResultsFromApi',
        searchedFirstName,
        searchedLastName,
        locationNumber,
        cfaOneOnly,
      });

      return epicHelper(
        getGuestSearchResultsFromApi(
          searchedFirstName,
          searchedLastName,
          accessToken,
          locationNumber,
          cfaOneOnly,
        ),
        guestActions.guestSearchSuccess,
        guestActions.guestSearchFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetGuestEmailSearchResults: Epic<
  | ReturnType<(typeof guestActions)['guestSearchEmail']>
  | ReturnType<(typeof guestActions)['guestSearchEmailSuccess']>
  | ReturnType<(typeof guestActions)['guestSearchEmailFailure']>,
  | ReturnType<(typeof guestActions)['guestSearchEmail']>
  | ReturnType<(typeof guestActions)['guestSearchEmailSuccess']>
  | ReturnType<(typeof guestActions)['guestSearchEmailFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.GUEST_SEARCH_EMAIL_REQUEST),
    debounceTime(500),
    switchMap(() => {
      const state = store.value;
      const searchedEmail = selectEmail(state) as string;
      const isValidEmail = selectValidEmail(state);
      const accessToken = selectAccessToken(state);

      if (!isValidEmail) {
        return of(guestActions.guestSearchEmailSuccess([]));
      }

      leaveBreadcrumb('Get Guest Email Search Results', {
        message: 'Calling getGuestEmailSearchResultsFromApi',
        searchedEmail,
        isValidEmail,
      });

      return epicHelper(
        getGuestEmailSearchResultsFromApi(searchedEmail, accessToken),
        guestActions.guestSearchEmailSuccess,
        guestActions.guestSearchEmailFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetGuestPhoneSearchResults: Epic<
  | ReturnType<(typeof guestActions)['guestSearchPhone']>
  | ReturnType<(typeof guestActions)['guestSearchPhoneSuccess']>
  | ReturnType<(typeof guestActions)['guestSearchPhoneFailure']>,
  | ReturnType<(typeof guestActions)['guestSearchPhone']>
  | ReturnType<(typeof guestActions)['guestSearchPhoneSuccess']>
  | ReturnType<(typeof guestActions)['guestSearchPhoneFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.GUEST_SEARCH_PHONE_REQUEST),
    debounceTime(500),
    switchMap(() => {
      const state = store.value;
      const searchedPhone = selectPhoneFormForApi(state);
      const isValidPhone = selectValidPhone(state);
      const accessToken = selectAccessToken(state);

      if (!isValidPhone) {
        return of(guestActions.guestSearchPhoneSuccess([]));
      }

      leaveBreadcrumb('Get Guest Phone Search Results', {
        message: 'Calling getGuestPhoneSearchResultsFromApi',
        searchedPhone,
        isValidPhone,
      });

      return epicHelper(
        getGuestPhoneSearchResultsFromApi(searchedPhone, accessToken),
        guestActions.guestSearchPhoneSuccess,
        guestActions.guestSearchPhoneFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetPayment: Epic<
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['paymentSuccess']>
  | ReturnType<(typeof guestActions)['paymentFailure']>,
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['paymentSuccess']>
  | ReturnType<(typeof guestActions)['paymentFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.GUEST_MASQUERADE_SESSION_SUCCESS),
    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state) as string;

      leaveBreadcrumb('Get Payment', {
        message: 'Calling getPaymentFromApi',
      });

      return epicHelper(
        getPaymentFromApi(apiKey),
        guestActions.paymentSuccess,
        guestActions.paymentFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetOrderPaymentAccount: Epic<
  | ReturnType<(typeof orderActions)['initiateEditOrder']>
  | ReturnType<(typeof guestActions)['paymentSuccess']>
  | ReturnType<(typeof guestActions)['paymentFailure']>,
  | ReturnType<(typeof orderActions)['initiateEditOrder']>
  | ReturnType<(typeof guestActions)['paymentSuccess']>
  | ReturnType<(typeof guestActions)['paymentFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.INITIATE_EDIT_ORDER),
    // Don't get vaulted cards here if reordering
    filter((action) => {
      const { reorder } = action as ReturnType<(typeof orderActions)['initiateEditOrder']>;
      return !reorder;
    }),
    switchMap((action) => {
      const {
        order: { payment },
        reorder,
      } = action as ReturnType<(typeof orderActions)['initiateEditOrder']>;

      leaveBreadcrumb('Get Order Payment Account', {
        message: 'Routing payments, vaulted cards',
        reorder,
        payment,
      });

      // If reordering then use the already vaulted cards from state
      if (reorder === true) {
        const state = store.value;
        const vaultedCards = selectVaultedCards(state);
        return of({ vaultedCards, payment });
      }

      // If a traditional edit order, then wait for vaulted payment to return
      return action$.pipe(
        ofType(guestTypes.GUEST_PAYMENT_SUCCESS),
        take(1),
        map((innerAction) => {
          const { vaultedCards } = innerAction as ReturnType<(typeof guestActions)['paymentSuccess']>;
          return { vaultedCards, payment };
        }),
      );
    }),
    filter(({ payment }) => {
      if (!payment) {
        return false;
      }

      leaveBreadcrumb('Get Order Payment Account Filtering On Payment', {
        message: 'Filtering on payment type of ACCOUNT',
        payment,
      });

      return payment.paymentType === 'ACCOUNT';
    }),
    switchMap(({ vaultedCards, payment }) => {
      const { accountId } = payment as OrderPayment;

      let foundAccount: Card | PaymentOption | undefined;
      if (Array.isArray(vaultedCards)) {
        foundAccount = find<typeof foundAccount>(propEq('id', accountId), vaultedCards);
      }

      if (foundAccount) {
        return of(guestActions.getOrderAccountSuccess(foundAccount));
      }

      const state = store.value;
      const apiKey = selectApiKey(state) as string;

      leaveBreadcrumb('Get Order Payment Account By ID', {
        message: 'Calling lookupPaymentByAccountId',
        accountId,
      });

      return epicHelper(
        lookupPaymentByAccountId(accountId as string, apiKey),
        guestActions.getOrderAccountSuccess,
        guestActions.getOrderAccountFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const ValidateZip: Epic<
  | ReturnType<(typeof guestActions)['validateZip']>
  | ReturnType<(typeof guestActions)['validateZipSuccess']>
  | ReturnType<(typeof guestActions)['validateZipFailure']>,
  | ReturnType<(typeof guestActions)['validateZip']>
  | ReturnType<(typeof guestActions)['validateZipSuccess']>
  | ReturnType<(typeof guestActions)['validateZipFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.GUEST_VALIDATE_ZIP_REQUEST),
    switchMap((action) => {
      const { zip, card } = action as ReturnType<(typeof guestActions)['validateZip']>;
      const state = store.value;
      const apiKey = selectApiKey(state) as string;

      const { accountDisplay, cardType, validated } = card;

      leaveBreadcrumb('Validate Card Zipcode', {
        message: 'Calling validateZipFromApi',
        card: { accountDisplay, cardType, validated },
      });

      return epicHelper(
        validateZipFromApi(zip, card, apiKey),
        guestActions.validateZipSuccess,
        guestActions.validateZipFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetPastOrders: Epic<
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['getMorePastOrders']>
  | ReturnType<(typeof guestActions)['getPastOrdersSuccess']>
  | ReturnType<(typeof guestActions)['getPastOrdersFailure']>
  | ReturnType<(typeof guestActions)['getMorePastOrdersSuccess']>
  | ReturnType<(typeof guestActions)['getMorePastOrdersFailure']>,
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['getMorePastOrders']>
  | ReturnType<(typeof guestActions)['getPastOrdersSuccess']>
  | ReturnType<(typeof guestActions)['getPastOrdersFailure']>
  | ReturnType<(typeof guestActions)['getMorePastOrdersSuccess']>
  | ReturnType<(typeof guestActions)['getMorePastOrdersFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.GUEST_MASQUERADE_SESSION_SUCCESS, guestTypes.GET_MORE_PAST_ORDERS),
    switchMap((action) => {
      const { key } = action as ReturnType<(typeof guestActions)['getMorePastOrders']>;
      const state = store.value;
      const apiKey = selectApiKey(state) as string;
      const pastOrdersLength = selectPastOrdersLength(state);
      const numToLoad = pastOrdersLength + ooeConstants.NUM_ADDTL_PAST_ORDERS_TO_LOAD;
      let successAction = guestActions.getPastOrdersSuccess;
      let failureAction = guestActions.getPastOrdersFailure;
      if (key === keys.GET_MORE_PAST_ORDERS) {
        successAction = guestActions.getMorePastOrdersSuccess;
        failureAction = guestActions.getMorePastOrdersFailure;
      }

      leaveBreadcrumb('Get Past Orders', {
        message: 'Calling getGuestPastOrders',
        numToLoad,
      });

      return epicHelper(
        getGuestPastOrders(apiKey, numToLoad),
        successAction,
        failureAction,
        epicOptions(store, action$),
      );
    }),
  );

export const GetPastDeliveryAdresses: Epic<
  | ReturnType<(typeof guestActions)['getPastDeliveryAddresses']>
  | ReturnType<(typeof guestActions)['getPastDeliveryAddressesSuccess']>
  | ReturnType<(typeof guestActions)['getPastDeliveryAddressesFailure']>,
  | ReturnType<(typeof guestActions)['getPastDeliveryAddresses']>
  | ReturnType<(typeof guestActions)['getPastDeliveryAddressesSuccess']>
  | ReturnType<(typeof guestActions)['getPastDeliveryAddressesFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.GET_PAST_DELIVERY_ADDRESSES),
    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state) as string;
      const numToLoad = ooeConstants.NUM_OF_PAST_ORDERS_FOR_DELIVERY_ADDRESSES;

      return epicHelper(
        getGuestPastOrders(apiKey, numToLoad),
        guestActions.getPastDeliveryAddressesSuccess,
        guestActions.getPastDeliveryAddressesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetFavoriteOrders: Epic<
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['addToFavoritesSuccess']>
  | ReturnType<(typeof guestActions)['removeFromFavoritesSuccess']>
  | ReturnType<(typeof guestActions)['updateFavoriteNameSuccess']>
  | ReturnType<(typeof guestActions)['getFavoriteOrdersSuccess']>
  | ReturnType<(typeof guestActions)['getFavoriteOrdersFailure']>,
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['addToFavoritesSuccess']>
  | ReturnType<(typeof guestActions)['removeFromFavoritesSuccess']>
  | ReturnType<(typeof guestActions)['updateFavoriteNameSuccess']>
  | ReturnType<(typeof guestActions)['getFavoriteOrdersSuccess']>
  | ReturnType<(typeof guestActions)['getFavoriteOrdersFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(
      guestTypes.GUEST_MASQUERADE_SESSION_SUCCESS,
      guestTypes.ADD_TO_FAVORITES_SUCCESS,
      guestTypes.REMOVE_FROM_FAVORITES_SUCCESS,
      guestTypes.UPDATE_FAVORITE_NAME_SUCCESS,
    ),
    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state) as string;
      const locationNumber = selectLocationNumber(state);
      const destination = selectDestination(state);

      leaveBreadcrumb('Get Favorite Orders', {
        message: 'Calling getFavoriteOrders',
      });

      return epicHelper(
        getFavoriteOrders(apiKey, locationNumber, destination, true),
        guestActions.getFavoriteOrdersSuccess,
        guestActions.getFavoriteOrdersFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const AddToFavorites: Epic<
  | ReturnType<(typeof guestActions)['addToFavorites']>
  | ReturnType<(typeof guestActions)['addToFavoritesSuccess']>
  | ReturnType<(typeof guestActions)['addToFavoritesFailure']>,
  | ReturnType<(typeof guestActions)['addToFavorites']>
  | ReturnType<(typeof guestActions)['addToFavoritesSuccess']>
  | ReturnType<(typeof guestActions)['addToFavoritesFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.ADD_TO_FAVORITES),
    switchMap((action) => {
      const { orderId, favoriteName } = action as ReturnType<(typeof guestActions)['addToFavorites']>;
      const state = store.value;
      const apiKey = selectApiKey(state) as string;

      leaveBreadcrumb('Add To Favorites', {
        message: 'Calling addToFavorites',
        orderId,
        favoriteName,
      });

      return epicHelper(
        addToFavorites(orderId, favoriteName, apiKey),
        guestActions.addToFavoritesSuccess,
        guestActions.addToFavoritesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const RemoveFromFavorites: Epic<
  | ReturnType<(typeof guestActions)['removeFromFavorites']>
  | ReturnType<(typeof guestActions)['removeFromFavoritesSuccess']>
  | ReturnType<(typeof guestActions)['removeFromFavoritesFailure']>,
  | ReturnType<(typeof guestActions)['removeFromFavorites']>
  | ReturnType<(typeof guestActions)['removeFromFavoritesSuccess']>
  | ReturnType<(typeof guestActions)['removeFromFavoritesFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.REMOVE_FROM_FAVORITES),
    switchMap((action) => {
      const { favoriteOrderId } = action as ReturnType<(typeof guestActions)['removeFromFavorites']>;
      const state = store.value;
      const apiKey = selectApiKey(state) as string;

      leaveBreadcrumb('Remove From Favorites', {
        message: 'Calling removeFromFavorites',
        favoriteOrderId,
      });

      return epicHelper(
        removeFromFavorites(favoriteOrderId, apiKey),
        guestActions.removeFromFavoritesSuccess,
        guestActions.removeFromFavoritesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const UpdateFavoriteName: Epic<
  | ReturnType<(typeof guestActions)['updateFavoriteName']>
  | ReturnType<(typeof guestActions)['updateFavoriteNameSuccess']>
  | ReturnType<(typeof guestActions)['updateFavoriteNameFailure']>,
  | ReturnType<(typeof guestActions)['updateFavoriteName']>
  | ReturnType<(typeof guestActions)['updateFavoriteNameSuccess']>
  | ReturnType<(typeof guestActions)['updateFavoriteNameFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(guestTypes.UPDATE_FAVORITE_NAME),
    switchMap((action) => {
      const { favoriteOrderId, favoriteName } = action as ReturnType<
        (typeof guestActions)['updateFavoriteName']
      >;
      const state = store.value;
      const apiKey = selectApiKey(state) as string;

      leaveBreadcrumb('Update Favorite Name', {
        message: 'Calling updateFavoriteName',
        favoriteOrderId,
        favoriteName,
      });

      return epicHelper(
        updateFavoriteName(favoriteOrderId, favoriteName, apiKey),
        guestActions.updateFavoriteNameSuccess,
        guestActions.updateFavoriteNameFailure,
        epicOptions(store, action$),
      );
    }),
  );

export default [
  AuthenticateOnGuestBehalf,
  InitiateGuestSession,
  GetGuestSearchResults,
  GetGuestEmailSearchResults,
  GetGuestPhoneSearchResults,
  GetPayment,
  GetOrderPaymentAccount,
  ValidateZip,
  RefreshToken,
  GetPastOrders,
  GetFavoriteOrders,
  AddToFavorites,
  RemoveFromFavorites,
  UpdateFavoriteName,
  GetPastDeliveryAdresses,
];
