import { Button, LoadingIndicator } from '@cfa/react-components';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { constructNow, getUnixTime, parseISO, startOfDay } from 'date-fns';
import React, { ChangeEvent, memo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';

import DashboardHeader from '../components/DashboardHeader/DashboardHeader';
import DashboardTable from '../components/DashboardTable/DashboardTable';
import EmptyCart from '../components/EmptyCart/EmptyCart';
import { selectDetailViewErrors, selectTooLateToCancelOrEdit, type State } from '../reducers';
import {
  actions as dashboardActions,
  selectAllowExcelDownload,
  selectDashboardActionLoading,
  selectDashboardPastOrderLoading,
  selectExportEntireDaysExcelError,
  selectExtractOrdersData,
  selectExtractOrdersDataLoading,
  selectIsErrorStatus,
  selectIsPOSOrder,
  selectLookupOrderIsLoading,
  selectOrderHistory,
  selectOrders,
  selectOrdersAreLoading,
  selectOrdersError,
  selectOrderToView,
  selectPaymentEmailResent,
  selectSpecificDays,
} from '../reducers/dashboard';
import { actions as orderActions } from '../reducers/order';
import {
  selectBypassBusinessRules,
  selectLocationContactDetails,
  selectLocationNumber,
} from '../reducers/user';

const capitalize = ([first, ...rest]: string) => (first ? first.toUpperCase() + rest.join('') : '');

export const Dashboard: React.FC<Props> = (props) => {
  const {
    tooLateToCancelOrEdit = false,
    ordersAreLoading = false,
    ordersError = false,
    getOrders,
    orders = [],
    loadMorePastOrders,
    dashboardPastOrdersLoading = false,
    getOrdersForSpecificDays,
    getDataForExcelExtraction,
    excelExtractData = [],
    allowExcelDownload = false,
    downloadExcel,
    exportExcelOrdersLoading = false,
    excelExtractionError = false,
    specificDays = {},
    selectedLocation = '',
    cancelOrder = () => {},
    lookupCustomerDetails = () => {},
    lookupOrderDetails = () => {},
    resendPaymentEmail = () => {},
  } = props;
  const location = useLocation();
  const [orderType, setOrderType] = useState('upcoming');
  const isUpcoming = orderType === 'upcoming';

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setOrderType(e.target.value.toLowerCase());
  };

  const onCalendarOrdersType = (type: string) => {
    setOrderType(type);
  };

  const renderOrders = (type: string) => {
    const renderableOrders = orders.filter((order) => {
      const diff =
        getUnixTime(parseISO(order.promiseDateTime)) - getUnixTime(startOfDay(constructNow(new Date())));
      if (type === 'upcoming') {
        return diff >= 0;
      }
      return diff < 0;
    });
    const noOrders = orders.length === 0;

    if (ordersAreLoading) {
      return (
        <StyledSpinnerWrapper>
          <LoadingIndicator data-testid="spinner" size="md" variant="page" />
        </StyledSpinnerWrapper>
      );
    }
    if (ordersError) {
      return (
        <>
          <SectionHeader>Oops!</SectionHeader>
          <p>
            There was a problem retrieving
            {` ${type} `}
            orders. Please try again or contact support.
          </p>
          <Button color="secondary" onClick={getOrders} size="md">
            Try Again
          </Button>
        </>
      );
    }
    if (noOrders) {
      return <EmptyCart className="empty-cart" message={`No ${capitalize(type)} Orders`} />;
    }
    return (
      <>
        <DashboardTable
          {...props}
          cancelOrder={cancelOrder}
          dashboardPastOrdersLoading={dashboardPastOrdersLoading}
          loadMorePastOrders={loadMorePastOrders}
          location={location}
          lookupCustomerDetails={lookupCustomerDetails}
          lookupOrderDetails={lookupOrderDetails}
          orders={renderableOrders}
          resendPaymentEmail={resendPaymentEmail}
          tooLateToCancelOrEdit={type === 'past' ? true : tooLateToCancelOrEdit}
          type={type}
        />
      </>
    );
  };

  return (
    <Section>
      <DashboardHeader
        allowExcelDownload={allowExcelDownload}
        downloadExcel={downloadExcel}
        excelExtractData={excelExtractData}
        excelExtractionError={excelExtractionError}
        exportExcelOrdersLoading={exportExcelOrdersLoading}
        getDataForExcelExtraction={getDataForExcelExtraction}
        getOrders={getOrders}
        getOrdersForSpecificDays={getOrdersForSpecificDays}
        onCalendarOrdersType={onCalendarOrdersType}
        onSwitchTabs={onChangeHandler}
        orders={orders}
        selectedLocation={selectedLocation}
        specificDays={specificDays}
      />
      <div className="upcoming-table" style={isUpcoming ? {} : { display: 'none' }}>
        {renderOrders('upcoming')}
      </div>
      <div className="past-table" style={isUpcoming ? { display: 'none' } : {}}>
        {renderOrders('past')}
      </div>
    </Section>
  );
};

const StyledSpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20vh auto;
`;

function mapStateToProps(state: State) {
  return {
    orders: selectOrders(state),
    orderToView: selectOrderToView(state),
    lookupOrderDetailsIsLoading: selectLookupOrderIsLoading(state),
    restaurantContactDetails: selectLocationContactDetails(state),
    dashboardActionLoading: selectDashboardActionLoading(state),
    ordersAreLoading: selectOrdersAreLoading(state),
    ordersError: selectOrdersError(state),
    detailViewErrors: selectDetailViewErrors(state),
    bypassBusinessRules: selectBypassBusinessRules(state),
    tooLateToCancelOrEdit: selectTooLateToCancelOrEdit(state),
    paymentEmailResent: selectPaymentEmailResent(state),
    orderHistory: selectOrderHistory(state),
    isPOSOrder: selectIsPOSOrder(state),
    isErrorStatus: selectIsErrorStatus(state),
    dashboardPastOrdersLoading: selectDashboardPastOrderLoading(state),
    exportExcelOrdersLoading: selectExtractOrdersDataLoading(state),
    excelExtractData: selectExtractOrdersData(state),
    allowExcelDownload: selectAllowExcelDownload(state),
    excelExtractionError: selectExportEntireDaysExcelError(state),
    specificDays: selectSpecificDays(state),
    selectedLocation: selectLocationNumber(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ ...dashboardActions, ...orderActions }, dispatch);
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = Partial<ConnectedProps<typeof connector>>;

export default connector(memo(Dashboard));
