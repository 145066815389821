import { Button, Drawer, Icon, IconButton, Typography } from '@cfa/react-components';
import React, { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Close } from '@cfa/system-icons';
import Section from '../components/Section';
import EmptyCart from '../components/EmptyCart/EmptyCart';
import Totals from '../components/Totals/Totals';
import { selectCartWithPrices, selectTaxAndTotal } from '../reducers';
import {
  selectFormattedSubTotalAmount,
  selectFormattedTaxAmount,
  selectOrderIsLoading,
} from '../reducers/order';
import usePrevious from '../util/customHooks';
import CartItem from './components/CartItem';
import { selectPromoFreeActive } from './selectors';

const Cart: React.FC = () => {
  const location = useLocation<{ cartItemId?: Nullable<string>; modal?: boolean }>();
  const history = useHistory();
  const cartItemId = location.state && location.state.cartItemId;
  const visible = !!(location.state && location.state.modal);

  const cartItems = useSelector(selectCartWithPrices);
  const subTotal = useSelector(selectFormattedSubTotalAmount);
  const taxAndTotal = useSelector(selectTaxAndTotal);
  const orderLoading = useSelector(selectOrderIsLoading);
  const promoFreeActive = useSelector(selectPromoFreeActive);

  const taxAmount = useSelector(selectFormattedTaxAmount);
  const itemRef = useRef<HTMLDivElement>(null);
  const prevCartItemId = usePrevious({ cartItemId });
  const ensureActiveItemVisible = () => {
    if (itemRef.current && itemRef.current.scrollIntoView) {
      itemRef.current.scrollIntoView({ block: 'center' });
    }
  };

  useEffect(() => {
    ensureActiveItemVisible();
  }, []);
  useEffect(() => {
    if (prevCartItemId?.cartItemId !== cartItemId) {
      ensureActiveItemVisible();
    }
  }, [cartItemId]);

  const renderCartItem = (item: (typeof cartItems)[number]) => {
    const { id } = item;
    const active = id === cartItemId;
    const renderProps = { key: id };
    return (
      <div {...renderProps} data-testid="cart-item" key={id + active} ref={itemRef}>
        <CartItem editMode={active} item={item} />
      </div>
    );
  };

  const goBackState = {
    modal: false,
    cardModal: false,
    cartItemId: null,
  };

  return (
    <Drawer
      className="cart"
      content={
        <StyledCartSection padding="0">
          <StyledCartHeader>
            <StyledCartSpacerDiv />
            <Typography color="tertiary" fontWeight="bold" variant="h4">
              Cart
            </Typography>
            <NavLink
              to={{
                state: {
                  modal: false,
                  cartItemId: null,
                },
              }}
            >
              <IconButton
                aria-label="close"
                color="tertiary"
                data-cy="done"
                size="md"
                style={{ margin: '4px' }}
              >
                <Icon icon={Close} />
              </IconButton>
            </NavLink>
          </StyledCartHeader>
          {cartItems.length > 0 ? (
            <>
              <StyledCartItems>{cartItems.map(renderCartItem)}</StyledCartItems>
              <StyledCartTotals>
                <Totals
                  isLoading={orderLoading}
                  promoFreeActive={promoFreeActive}
                  subTotal={subTotal}
                  taxAmount={taxAmount}
                  taxAndTotal={taxAndTotal}
                />
                <NavLink
                  style={{ textDecoration: 'none' }}
                  to={{ pathname: '/payment', state: { modal: false } }}
                >
                  <CheckoutButton
                    className="check-out-button"
                    color="secondary"
                    data-cy="check-out-button"
                    fullWidth
                  >
                    Check Out
                  </CheckoutButton>
                </NavLink>
              </StyledCartTotals>
            </>
          ) : (
            <EmptyCart className="empty-cart" message="Cart Empty" />
          )}
        </StyledCartSection>
      }
      onClose={() => history.push({ state: goBackState })}
      open={visible}
      title="Cart"
      width="wide"
    />
  );
};

const StyledCartSection = styled(Section)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledCartItems = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
`;

const StyledCartTotals = styled.div`
  border-top: 2px solid ${(props) => props.theme.colors.disabled};
  padding-top: 12px;
  margin-left: 20px;
  margin-right: 20px;
`;

const StyledCartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #004f71;
  height: 64px;
`;

const StyledCartSpacerDiv = styled.div`
  width: 40px;
  height: 40px;
`;

const CheckoutButton = styled(Button)`
  min-width: 50%;
  margin: 40px auto;
  display: block;
`;

export default memo(Cart);
