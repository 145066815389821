import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { actions as orderActions } from '../../../reducers/order';
import { OrderDeliveryAddress } from '../../../types/order';

interface Props {
  address: OrderDeliveryAddress;
}

const PastDeliveryAddressesItem: React.FC<Props> = ({ address }) => {
  const dispatch = useDispatch();
  const { addressLine1, addressLine2, addressLine3, city, state, zip } = address;
  const getStreetNumber = addressLine1 == null ? '' : addressLine1.split(' ');
  const [streetNumber, ...adrs] = getStreetNumber;
  const route = adrs.join(' ');
  const routeForId = adrs.join('');
  const addressToValidate = `${streetNumber} ${route} ${addressLine2} ${city} ${state} ${zip}`;

  const chooseAddressHandler = () => {
    dispatch(orderActions.updateUserAddress(streetNumber, 'streetNumber'));
    dispatch(orderActions.updateUserAddress(route, 'route'));
    dispatch(orderActions.updateUserAddress(addressLine2 || '', 'subpremise'));
    dispatch(orderActions.updateUserAddress(city || '', 'locality'));
    dispatch(orderActions.updateUserAddress(state || '', 'administrativeAreaLevelOne'));
    dispatch(orderActions.updateUserAddress(zip || '', 'postalCode'));
    dispatch(orderActions.autocompleteAddress(addressToValidate));
  };

  const label = `${addressLine1} ${addressLine2 == null ? '' : addressLine2} ${addressLine3 === null ? '' : addressLine3} ${city} ${state} ${zip}`;
  const id = `${streetNumber}-${routeForId}-${city}-${zip}`;
  return (
    <StyledRadio>
      <input className="radio" id={id} name="adrs" onChange={chooseAddressHandler} type="radio" value={id} />
      <label htmlFor={id}>{label}</label>
    </StyledRadio>
  );
};

const StyledRadio = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.disabled};
  & label {
    color: ${(props) => props.theme.colors.text} !important;
    padding: 1rem 0;
    width: 100%;
  }
  & .radio:checked,
  .radio:not(:checked) {
    display: none;
  }
  & .radio:checked + label,
  .radio:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: ${(props) => props.theme.colors.accent};
  }
  & .radio:checked + label:before,
  .radio:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 15px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  & .radio:checked + label:after,
  .radio:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: ${(props) => props.theme.colors.accent};
    position: absolute;
    top: 19px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }
  & .radio:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  & .radio:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
`;

export default PastDeliveryAddressesItem;
