/* eslint-disable react/forbid-prop-types */
import { Button, LoadingIndicator } from '@cfa/react-components';
import { Flex, Input } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { pathOr, toLower } from 'ramda';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import down from '../../assets/down.svg';
import up from '../../assets/up.svg';
import constants from '../../constants';
import Icon from '../Icon';
import Loyalty from '../Loyalty/Loyalty';
import DetailView from './DetailView';

function rowClassName(order) {
  const status = pathOr('', ['displayStatus', 'statusName'], order);
  let className = 'table-row wrapper';
  if (status === constants.ERROR) {
    className += ' error';
  }
  return className;
}

export function DashboardTable(props) {
  const {
    orderToView,
    lookupOrderDetailsIsLoading,
    location,
    type,
    loadMorePastOrders,
    dashboardPastOrdersLoading,
  } = props;

  const [filterBy, setFilterBy] = useState();
  const [sortAscending, setSortAscending] = useState({
    [constants.CUSTOMER_NAME]: true,
    [constants.UTC_TIME]: type === 'upcoming',
    [constants.TOTAL]: true,
    [constants.DESTINATION]: true,
    [constants.STATUS]: type === 'past',
    [constants.SOURCE]: true,
  });
  const [activeSortProperty, setActiveSortProperty] = useState(constants.UTC_TIME);

  const mapSortProperty = (property) => {
    setSortAscending({
      ...sortAscending,
      [property]: !sortAscending[property],
    });
    setActiveSortProperty(property);
  };

  const sortOrders = (order, nextOrder) => {
    if (activeSortProperty === constants.TOTAL || activeSortProperty === constants.UTC_TIME) {
      if (sortAscending[activeSortProperty]) {
        return order[activeSortProperty] - nextOrder[activeSortProperty];
      }
      return nextOrder[activeSortProperty] - order[activeSortProperty];
    }
    const itemA = toLower(order[activeSortProperty]);
    const itemB = toLower(nextOrder[activeSortProperty]);
    if (itemA < itemB) {
      return sortAscending[activeSortProperty] ? 1 : -1;
    }
    if (itemA > itemB) {
      return sortAscending[activeSortProperty] ? -1 : 1;
    }
    return 0;
  };

  const filterOrders = (order) => {
    if (!filterBy) {
      return order;
    }
    return order.customerName && order.customerName.toLowerCase().indexOf(filterBy.toLowerCase()) !== -1;
  };

  const renderArrows = (property) => {
    if (sortAscending[property]) {
      return <img alt="Down arrow" className="arrow" src={down} />;
    }
    return <img alt="Up arrow" className="arrow" src={up} />;
  };

  // eslint-disable-next-line react/destructuring-assignment
  const orders = props.orders.filter(filterOrders).sort(sortOrders);

  const showOrderDetails = !!(
    location.state &&
    location.state.orderDetailsId &&
    orders.find((order) => order.id === location.state.orderDetailsId)
  );

  const loadMorePastOrdersHandler = () => {
    loadMorePastOrders();
  };

  const renderLoadPastOrdersButton = () => {
    let buttonLoad = (
      <Button
        className="load-more-button"
        color="secondary"
        fullWidth
        onClick={loadMorePastOrdersHandler}
        variant="text"
      >
        Load One More Past Day
      </Button>
    );
    if (dashboardPastOrdersLoading) {
      buttonLoad = <LoadingIndicator className="spinner" size="sm" variant="inline" />;
    }
    return buttonLoad;
  };

  return (
    <StyledDashboardTable>
      <div className="table-row header">
        <div className="cell" onClick={() => mapSortProperty(constants.CUSTOMER_NAME)} role="presentation">
          <div>Guest Name</div>
          {renderArrows(constants.CUSTOMER_NAME)}
        </div>
        <div className="cell large" onClick={() => mapSortProperty(constants.UTC_TIME)} role="presentation">
          <div>Date</div>
          {renderArrows(constants.UTC_TIME)}
        </div>
        <div
          className="cell small"
          onClick={() => mapSortProperty(constants.DESTINATION)}
          role="presentation"
        >
          <div>Method</div>
          {renderArrows(constants.DESTINATION)}
        </div>
        <div className="cell small" onClick={() => mapSortProperty(constants.TOTAL)} role="presentation">
          <div>Total</div>
          {renderArrows(constants.TOTAL)}
        </div>
        <div className="cell" onClick={() => mapSortProperty(constants.STATUS)} role="presentation">
          <div>Payment Status</div>
          {renderArrows(constants.STATUS)}
        </div>
        <div className="cell" onClick={() => mapSortProperty(constants.SOURCE)} role="presentation">
          <div>Source</div>
          {renderArrows(constants.SOURCE)}
        </div>
      </div>
      <Input
        autoFocus
        data-cy="order-filter"
        mx="0"
        onChange={(e) => setFilterBy(e.target.value)}
        p="15px"
        placeholder="Search guest name"
        type="search"
        width={1}
      />
      {orders.map((order) => (
        <NavLink
          className={rowClassName(order)}
          data-cy={`order-${order.id}`}
          key={order.id}
          role="presentation"
          style={{ textDecoration: 'none', color: 'inherit' }}
          to={{ state: { orderDetailsId: order.id } }}
        >
          <div className="data cell name">
            {order.cfaLoyalty && <Loyalty cfaLoyalty={order.cfaLoyalty} />}
            {order.customerName}
          </div>
          <div className="data cell large date">{order.displayLongDate}</div>
          <div className="data cell small method">{order.destination}</div>
          <div className="data cell small total">{order.displayPrice}</div>
          <div className="data cell status" data-cy={`${order.id}-status`}>
            <Icon>{order.displayStatus.statusIcon}</Icon>
            {order.displayStatus.statusName}
          </div>
          <div className="data cell source" data-cy={`${order.id}-source`}>
            {order.displayClient}
          </div>
        </NavLink>
      ))}
      {type === 'past' && (
        <Flex className="more-past-orders-box" justifyContent="center">
          {renderLoadPastOrdersButton()}
        </Flex>
      )}
      {showOrderDetails && (
        <DetailView
          key={`${location.state.orderDetailsId}-details`}
          {...props}
          baseOrder={orders.find((order) => order.id === location.state.orderDetailsId)}
          lookupDetailsLoading={lookupOrderDetailsIsLoading}
          orderDetails={orderToView}
        />
      )}
    </StyledDashboardTable>
  );
}

const StyledDashboardTable = styled.div`
  & .table-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    min-height: 58px;
  }
  & .header {
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
    border-bottom: 1px solid #eaeaea;
    text-align: center;
    align-items: center;
  }
  & .data {
    border: 1px solid #eaeaea;
  }
  & .cell {
    display: flex;
    align-items: center;
    width: 20%;
    padding: 0 10px;
  }
  & .header > .cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  & .large {
    width: 40%;
  }
  & .small {
    width: 10%;
  }
  & .table-row:hover {
    cursor: pointer;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
  }
  & .table-row:nth-child(even) {
    background-color: ${(props) => props.theme.colors.background};
  }
  & .error {
    background-color: #d4000026 !important;
    font-weight: bold;
    color: #e60909;
  }
  & .arrow {
    width: 8px;
    height: 20px;
    margin-left: 4px;
  }
  & .status {
    display: flex;
    align-items: center;
  }
  & .status svg {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    max-width: 21px;
  }
  & .no-addresses {
    text-align: center;
  }
  & .spinner {
    margin-top: 1rem;
  }
  @media (max-width: ${(props) => props.theme.phone}) {
    & .wrapper,
    .header {
      flex-direction: column;
      border: 1px solid #eaeaea;
      padding: 10px;
      text-align: left;
    }
    & .cell {
      border: none;
      width: 100%;
      line-height: 24px;
    }
    & .table-row {
      width: inherit;
    }
    & .header {
      display: none;
    }
  }
`;

DashboardTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.any),
  cancelOrder: PropTypes.func.isRequired,
  dashboardActionLoading: PropTypes.bool,
  lookupOrderDetails: PropTypes.func.isRequired,
  lookupCustomerDetails: PropTypes.func.isRequired,
  lookupOrderDetailsIsLoading: PropTypes.bool,
  orderToView: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ]),
  ),
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
  detailViewErrors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  tooLateToCancelOrEdit: PropTypes.bool,
  resendPaymentEmail: PropTypes.func.isRequired,
  paymentEmailResent: PropTypes.bool,
  orderHistory: PropTypes.arrayOf(PropTypes.any),
  isPOSOrder: PropTypes.bool,
  isErrorStatus: PropTypes.bool,
  initiateEditOrder: PropTypes.func,
  location: PropTypes.object.isRequired,
  type: PropTypes.string,
  loadMorePastOrders: PropTypes.func,
  dashboardPastOrdersLoading: PropTypes.bool,
};

DashboardTable.defaultProps = {
  orders: [],
  orderToView: {},
  restaurantContactDetails: {},
  lookupOrderDetailsIsLoading: false,
  dashboardActionLoading: false,
  detailViewErrors: [],
  tooLateToCancelOrEdit: false,
  paymentEmailResent: false,
  orderHistory: [],
  isPOSOrder: false,
  isErrorStatus: false,
  initiateEditOrder: () => {},
  type: 'upcoming',
  loadMorePastOrders: () => {},
  dashboardPastOrdersLoading: false,
};

export default DashboardTable;
