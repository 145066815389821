import { Input } from '@cfacorp/cowponents';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import React, { memo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { find, propEq } from 'ramda';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';
import StyledMenuItem from '../components/MenuItem/MenuItem';
import PaperGoods from '../components/PaperGoods/PaperGoods';
import { selectMenuWithQuantities, State } from '../reducers';
import { actions as cartActions } from '../cart/reducer';
import { selectNutrition } from '../reducers/menu';
import {
  actions as orderActions,
  selectEditMode,
  selectIsEditCMTOrder,
  selectPaperGoodsBool,
  selectPaperGoodsOptions,
  selectPaperGoodsYesOrNo,
} from '../reducers/order';
import type { Category, MenuItem } from '../types/menu';
import { selectCart } from '../cart/selectors';

export const Menu: React.FC<Props> = ({
  categories = [],
  addToCart,
  nutrition = [],
  cartItems = [],
  setPaperGoodsOptions = () => {},
  paperGoodsOptions = {},
  paperGoodsYesOrNo = '',
  isEditMode = false,
  isCMTOrder = false,
  paperGoodsBool = false,
}) => {
  const [filteredText, setFilteredText] = useState('');

  const filterItemsByText = (item: MenuItem) =>
    item.name && item.name.toLowerCase().includes(filteredText.toLowerCase());

  const filterCategoriesByText = (cat: Category) => cat.items.filter(filterItemsByText).length;

  const onFilterChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilteredText(e.target.value);
  };

  return (
    <div
      style={{
        maxWidth: 1010,
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
      }}
    >
      <Section>
        <SectionHeader>Paper Goods?</SectionHeader>
        <PaperGoods
          isCMTOrder={isCMTOrder}
          isEditMode={isEditMode}
          paperGoodsBool={paperGoodsBool}
          paperGoodsOptions={paperGoodsOptions}
          paperGoodsYesOrNo={paperGoodsYesOrNo}
          setPaperGoodsOptions={setPaperGoodsOptions}
        />
      </Section>
      <FilterInput
        autoFocus
        className="filter-input"
        onChange={onFilterChange}
        placeholder="Filter by…"
        type="search"
        value={filteredText}
      />
      {categories.filter(filterCategoriesByText).map((cat) => (
        <Section key={cat.tag}>
          <SectionHeader>{cat.name}</SectionHeader>
          <Items data-cy={`${cat.name}-menu-category`}>
            {cat.items.filter(filterItemsByText).map((item) => (
              <StyledMenuItem
                addToCart={addToCart}
                className="menu-item"
                hideQuantityInput
                item={item}
                key={item.tag}
                nutrition={find(propEq('tag', item.tag), nutrition)}
                quantity={cartItems
                  .filter((cartItem) => cartItem.tag === item.tag)
                  .reduce((count, cartItem) => count + cartItem.quantity, 0)}
              />
            ))}
          </Items>
        </Section>
      ))}
    </div>
  );
};

export const FilterInput = styled<'input'>(Input)`
  @media (min-width: 500px) {
    position: absolute;
    right: 22px;
  }
  float: right;
  height: 40px;
  width: 150px;
  padding: 7px;
  margin-top: 12px;
`;

const Items = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

function mapStateToProps(state: State) {
  return {
    categories: selectMenuWithQuantities(state),
    nutrition: selectNutrition(state),
    cartItems: selectCart(state),
    paperGoodsOptions: selectPaperGoodsOptions(state),
    paperGoodsYesOrNo: selectPaperGoodsYesOrNo(state),
    isEditMode: selectEditMode(state),
    isCMTOrder: selectIsEditCMTOrder(state),
    paperGoodsBool: selectPaperGoodsBool(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ ...cartActions, ...orderActions }, dispatch);
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = Partial<ConnectedProps<typeof connector>>;

export default connector(memo(Menu));
