import { Button } from '@cfa/react-components';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { destroy } from 'redux-form';
import styled from 'styled-components';

import { useHistory, useLocation } from 'react-router';
import Section from '../components/Section';
import FavoriteOrder from '../components/FavoriteOrder/FavoriteOrder';
import Icon from '../components/Icon';
import Tooltip from '../components/Tooltip/Tooltip';
import constants from '../constants';
import type { State } from '../reducers';
import {
  actions,
  selectCurrentFavoriteNames,
  selectFavoriteActionSuccessful,
  selectFavoritesError,
  selectFavoritesLoading,
  selectFavoriteSuggestedName,
} from '../reducers/guest';
import { selectTotalAmount, selectTransactionId } from '../reducers/order';
import { notifyBugsnag } from '../services/bugsnag';
import type Order from '../types/order';
import { formatLongDate, formatPrice } from '../util/format';

export const Submit: React.FC<Props> = ({
  addToFavorites = () => {},
  favoritesLoading = false,
  favoritesError = null,
  addToFavoritesSuccessful = false,
  suggestedFavoriteName = '',
  currentFavoriteNames = [],
  transactionId = '',
  destroy: destroyForm = () => {},
  clearGuestState = () => {},
  resetFavoriteActionErrors = () => {},
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  let sentBugsnagNotify = false;
  const history = useHistory();
  const { state: locationState = {} } = useLocation<
    | {
        order?: Order;
        guestName?: string;
        isCfaOneGuest?: boolean;
      }
    | undefined
  >();
  const { order, guestName, isCfaOneGuest } = locationState;
  const editOrder = pathOr('', ['error', 'editOrder'], order);
  useEffect(() => {
    destroyForm(
      constants.GET_FORM_TYPES.GUEST,
      constants.GET_FORM_TYPES.DETAILS,
      constants.GET_FORM_TYPES.PAYMENT_METHOD,
      constants.GET_FORM_TYPES.SECONDARY_CONTACT,
    );
  }, []);

  useEffect(
    () => () => {
      clearGuestState();
    },
    [],
  );

  const openModal = () => setModalIsOpen(true);

  const closeModal = ({ favoriteActionSuccessful }: { favoriteActionSuccessful: boolean }) => {
    setModalIsOpen(false);
    setIsFavorite(favoriteActionSuccessful);
    resetFavoriteActionErrors();
  };

  if (!locationState) {
    history.push('/');
    return null;
  }

  // on rare occasions we have seen order not exist in the incoming state
  // which caused exception/crash (consequences unknown)
  // to better diagnose this, we submit empty data for display and notify bugsnag
  const {
    subTotalAmount = 0,
    taxAmount = 0,
    destination = '',
    promiseDateTime = '1970-01-01T00:00:00',
    id = '',
    totalAmount = 0,
  } = order ?? {};

  if (!order && !sentBugsnagNotify) {
    notifyBugsnag('Missing order details on Submit view', {
      context: 'Submit View Missing Order Details',
      info: {
        order,
      },
    });
    sentBugsnagNotify = true;
  }

  return (
    <div>
      <Section>
        <Icon>celebrate</Icon>
        <h3>{`Order ${editOrder ? 'Updated' : 'Placed'} Successfully`}</h3>
      </Section>
      <Section className="summary">
        <p>
          {`${destination} Order #`}
          <span data-cy="order-id">{id}</span>
          {` for ${guestName}`}
        </p>
        <p data-cy="promise-date">{`Promise date: ${formatLongDate(promiseDateTime)}`}</p>
        <p data-cy="final-total">{`Total: ${totalAmount !== 0 ? formatPrice(totalAmount) : formatPrice(subTotalAmount + taxAmount)}`}</p>
        {transactionId && (
          <Tooltip
            className="transId-tooltip"
            tooltipContent={
              <div>
                <span className="title">Transaction ID:</span>
                <span> Year + Month + Day + Store Num + Transaction Number</span>
              </div>
            }
          >
            <p data-cy="trans-id">{`TransactionID: ${transactionId}`}</p>
          </Tooltip>
        )}
      </Section>
      <Section>
        {isFavorite && (
          <>
            <Icon height="20px" margin="5px" width="20px">
              favorite
            </Icon>
            <div className="favorite-message">{`This order has been added to ${guestName}'s Favorites`}</div>
          </>
        )}
        <Button
          onClick={() => {
            history.push('/');
          }}
        >
          Start New Order
        </Button>
        {isCfaOneGuest && !isFavorite && id && (
          <Button className="button-is-favorite" onClick={openModal}>
            Add to Favorites
          </Button>
        )}
      </Section>
      {modalIsOpen && isCfaOneGuest && id && (
        <FavoriteOrder
          addToFavorites={(favoriteName) => addToFavorites(id, favoriteName)}
          close={closeModal}
          currentFavoriteNames={currentFavoriteNames}
          favoriteActionSuccessful={addToFavoritesSuccessful}
          favoritesError={favoritesError}
          favoritesLoading={favoritesLoading}
          guestName={guestName}
          isFavorite={isFavorite}
          isOpen={modalIsOpen}
          suggestedFavoriteName={suggestedFavoriteName}
        />
      )}
    </div>
  );
};

export const StyledSubmit = styled(Submit)`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  & svg {
    fill: ${(props) => props.theme.colors.primary};
  }
  & .summary p {
    margin: 2px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: initial;
  }
  & .favorite-message {
    margin: 0 0 10px;
  }
  & .tooltip {
    top: 22px !important;
    left: unset !important;
    max-width: 80vw;
    .title {
      font-weight: bold;
    }
  }
`;

function mapStateToProps(state: State) {
  return {
    favoritesLoading: selectFavoritesLoading(state),
    favoritesError: selectFavoritesError(state),
    addToFavoritesSuccessful: selectFavoriteActionSuccessful(state),
    suggestedFavoriteName: selectFavoriteSuggestedName(state),
    currentFavoriteNames: selectCurrentFavoriteNames(state),
    transactionId: selectTransactionId(state),
    totalAmountFromApi: selectTotalAmount(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ destroy, ...actions }, dispatch);
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = Partial<ConnectedProps<typeof connector>>;

export default connector(StyledSubmit);
