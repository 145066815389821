import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import Icon from '../../../components/Icon';
import {
  selectDeliveryAddressValid,
  selectDeliveryError,
  selectValidateAddressLoading,
  selectValidateDeliveryRangeLoading,
} from '../../../reducers/order';

const ValidationMessage: React.FC = () => {
  const validAddress = useSelector(selectDeliveryAddressValid);
  const validationError = useSelector(selectDeliveryError);
  const validatingAddress = useSelector(selectValidateAddressLoading);
  const validatingDeliveryRange = useSelector(selectValidateDeliveryRangeLoading);

  let icon;
  let message;

  if (validationError) {
    icon = validationError.type.toLowerCase();
    message = validationError.message;
  } else if (validatingAddress || validatingDeliveryRange) {
    icon = 'loading';
    message = 'Validating address...';
  } else if (validAddress) {
    icon = 'success';
    message = 'Delivery location accepted';
  } else {
    icon = 'warning';
    message = 'Please complete all fields noted as required to process order';
  }

  return (
    <StyledValidationMessage>
      {icon !== undefined && <Icon>{icon}</Icon>}
      <div className="message">{message}</div>
    </StyledValidationMessage>
  );
};

const StyledValidationMessage = styled(Disclaimer)`
  height: 19px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  & svg {
    width: 17px;
    margin: 0 0 2px 0;
  }
  & .message {
    margin-left: 5px;
  }
`;

export default ValidationMessage;
