import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  tipPercentageButtonId: string;
  value: string;
  onClickHandler: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  checked?: boolean;
}

export const DeliveryTipsPercentageButton = React.forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  ({ tipPercentageButtonId, value, onClickHandler, disabled = false, children, checked = false }, ref) => (
    <StyledPercentageButton className="StyledPercentageButton">
      <input
        checked={checked}
        disabled={disabled}
        id={tipPercentageButtonId}
        name="percentage"
        onChange={onClickHandler}
        ref={ref}
        type="radio"
        value={value}
      />
      <label htmlFor={tipPercentageButtonId}>{children}</label>
    </StyledPercentageButton>
  ),
);

export const StyledPercentageButton = styled('div')`
  min-width: 85px;
  min-height: 35px;
  position: relative;
  margin: 5px;
  & label,
  & input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  & label {
    cursor: pointer;
    z-index: 90;
    width: 100%;
    height: 100%;
    text-align: center;
    border: solid 1px ${(props) => props.theme.colors.primary};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & input[type='radio'] {
    opacity: 0.011;
    z-index: 100;
    &:checked + label {
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.secondary};
    }
    &:disabled + label {
      background: ${(props) => props.theme.colors.disabled};
      color: ${(props) => props.theme.colors.text};
      border-color: ${(props) => props.theme.colors.text};
      cursor: auto;
    }
  }
`;

export default DeliveryTipsPercentageButton;
