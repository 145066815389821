import { Button } from '@cfa/react-components';
import { Box, Heading, Text } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon';
import OverlayModal from './OverlayModal';

const ItemWarningModal = ({ isOpen, message, onConfirm, onClose }) => (
  <StyledItemWarningModal close={onClose} isOpen={isOpen}>
    <Close onClick={onClose}>
      <Icon>close</Icon>
    </Close>
    <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" m="3em">
      <Icon>warning</Icon>
      <Heading as="h1" fontSize="5" m="1em">
        Would you still like to order it?
      </Heading>
      <Text color="textColor">{message}</Text>
      <Box alignItems="center" display="flex" justifyContent="space-around" m="1em" width="100%">
        <Button className="btn-yes" color="secondary" onClick={onConfirm}>
          Yes, add to order
        </Button>
        <Button className="btn-no" color="secondary" onClick={onClose} variant="outlined">
          No, go back
        </Button>
      </Box>
    </Box>
  </StyledItemWarningModal>
);

export const StyledItemWarningModal = styled(OverlayModal)`
  text-align: center;
  padding: 20px;
`;

export const Close = styled('div')`
  position: absolute;
  top: 0;
  right: 5px;
  & svg {
    margin: 0;
    width: 20px;
    fill: ${(props) => props.theme.colors.outline};
  }
  :hover {
    cursor: pointer;
  }
`;

ItemWarningModal.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

ItemWarningModal.defaultProps = {
  isOpen: false,
  message: '',
  onConfirm: () => {},
  onClose: () => {},
};

export default ItemWarningModal;
