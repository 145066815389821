import type { Location } from '../types/location';

export const loadLocationsFromStorage = () => {
  const storedLocations = localStorage.getItem('visitedLocations');
  return storedLocations ? JSON.parse(storedLocations) : [];
};

export const updateLocation = (locationNumber: string, name: string) => {
  const locations = loadLocationsFromStorage();
  const index = locations.findIndex((loc: Location) => loc.locationNumber === locationNumber);

  if (index < 0) {
    locations.push({ locationNumber, name, visits: 1 });
  } else {
    locations[index].visits += 1;
  }

  return localStorage.setItem('visitedLocations', JSON.stringify(locations));
};

export const getTopLocations = (maxNum: number) =>
  loadLocationsFromStorage()
    .sort((a: { visits: number }, b: { visits: number }) => b.visits - a.visits)
    .slice(0, maxNum)
    .map((loc: Location) => ({ locationNumber: loc.locationNumber, name: loc.name }));
