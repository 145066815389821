/* eslint-disable react/forbid-prop-types */
import { ToggleButton, ToggleButtonGroup } from '@cfa/react-components';
import { constructNow, format, parse } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';

import ooeConstants from '../../constants';
import { isSmallScreen } from '../../util/utils';
import DatePickerInput from '../DatePicker/DatePicker';
import ExportExcelEntireDays from '../ExportExcel/ExportExcelEntireDays';
import Icon from '../Icon';

export const types = ['upcoming', 'past'];

const DashboardHeader = (props) => {
  const {
    getOrders,
    onSwitchTabs,
    getOrdersForSpecificDays,
    onCalendarOrdersType,
    getDataForExcelExtraction,
    orders,
    excelExtractData,
    allowExcelDownload,
    downloadExcel,
    exportExcelOrdersLoading,
    excelExtractionError,
    specificDays,
    selectedLocation,
  } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ordersType, setOrdersType] = useState('upcoming');
  const [disableSegmentButtons, setDisableSegmentButtons] = useState(false);
  const [datePickerIsShown, setDatePickerIsShown] = useState(false);

  const capitalize = ([first, ...rest]) => (first ? first.toUpperCase() + rest.join('') : '');
  const orientation = window.matchMedia('(max-width: 600px)').matches ? 'vertical' : 'horizontal';
  const excelIcon = datePickerIsShown ? 'download-excel' : 'download-excel-disabled';

  const handleDatesChange = (event) => {
    const { startDate: calendarStartDate, endDate: calendarEndDate } = event;
    setStartDate(calendarStartDate);
    setEndDate(calendarEndDate);
    const formattedStart = format(calendarStartDate, ooeConstants.DATE_TIME_FORMAT.date);
    const formattedEnd = format(calendarEndDate, ooeConstants.DATE_TIME_FORMAT.date);
    const currentDate = format(constructNow(new Date()), ooeConstants.DATE_TIME_FORMAT.date);
    if (calendarStartDate && calendarEndDate) {
      getOrdersForSpecificDays(formattedStart, formattedEnd);
      setDatePickerIsShown(true);
    }

    if (formattedStart >= currentDate) {
      setOrdersType('upcoming');
      onCalendarOrdersType('upcoming');
      setDisableSegmentButtons(true);
    } else if (formattedEnd < currentDate) {
      setOrdersType('past');
      onCalendarOrdersType('past');
      setDisableSegmentButtons(true);
    } else {
      setDisableSegmentButtons(false);
    }
  };

  const onRefreshHandler = () => {
    getOrders();
    setDisableSegmentButtons(false);
    setStartDate(null);
    setEndDate(null);
    setDatePickerIsShown(false);
  };

  const onChangeHandler = (e) => {
    setOrdersType(e.target.value.toLowerCase());
    onSwitchTabs(e);
  };

  const onCancelDate = () => {
    onRefreshHandler();
    onCalendarOrdersType('upcoming');
    setOrdersType('upcoming');
  };

  // map through orders and call reducer action for getting data for excel extraction. this can't be run if date range is not chosen.
  const onOrdersExtractionHandler = () => orders.map((order) => getDataForExcelExtraction(order));

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const { startDate: startDateString, endDate: endDateString } = specificDays;
    if (startDateString) {
      const startDateObject = parse(startDateString, ooeConstants.DATE_TIME_FORMAT.date, new Date());
      setStartDate(startDateObject);
    }
    if (endDateString) {
      const endDateObject = parse(endDateString, ooeConstants.DATE_TIME_FORMAT.date, new Date());
      setEndDate(endDateObject);
    }
  }, [specificDays]);

  useEffect(() => {
    if (ordersType === 'past') {
      onCancelDate();
    }
  }, [selectedLocation]);

  return (
    <StyledSection padding={isSmallScreen() ? '0 0 5rem 0' : '0.5rem'}>
      <SectionHeader>
        <span data-testid="title">Orders</span>
        <DownloadExcelButton
          disabled={!datePickerIsShown}
          onClick={onOrdersExtractionHandler}
          style={exportExcelOrdersLoading ? { transform: 'rotate(-360deg)', transition: '0.5s' } : {}}
          title={
            datePickerIsShown ? 'Extract to Excel' : 'Select a date range to Exctract order data to Excel'
          }
        >
          <Icon className="excel-icon" height="30" margin="0" width="30">
            {exportExcelOrdersLoading ? 'loading' : excelIcon}
          </Icon>
        </DownloadExcelButton>
        <ExportExcelEntireDays
          allowExcelDownload={allowExcelDownload}
          dashboardOrders={orders}
          downloadExcel={downloadExcel}
          excelExtractionError={excelExtractionError}
          orders={excelExtractData}
          specificDays={specificDays}
        />
        <ReloadIconButton onClick={onRefreshHandler} title="Refresh orders">
          <Icon height="30" margin="0" width="30">
            refresh
          </Icon>
        </ReloadIconButton>
      </SectionHeader>
      <StyledControlBox>
        <StyledSegmentGroup
          className="order-type-buttons"
          onChange={onChangeHandler}
          size="sm"
          value={ordersType}
        >
          {types.map((type) => (
            <StyledSegmentButton disabled={disableSegmentButtons} key={type} value={type}>
              {`${capitalize(type)}`}
            </StyledSegmentButton>
          ))}
        </StyledSegmentGroup>
        <StyledDatePicker className={orientation}>
          <DatePickerInput
            endDate={endDate}
            onDatesChange={handleDatesChange}
            selectRange
            startDate={startDate}
          />
          {datePickerIsShown && (
            <Close className="button-clear" onClick={onCancelDate}>
              <Icon>close</Icon>
            </Close>
          )}
        </StyledDatePicker>
      </StyledControlBox>
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  position: relative;
`;

export const UnstyledButton = styled('button')`
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  padding: 0;
  display: inline-block;
  font: inherit;
  color: inherit;
  cursor: pointer;
`;

export const ReloadIconButton = styled(UnstyledButton)`
  position: absolute;
  right: 0;
  opacity: 0.6;
  transition: 0.5s all ease-in-out;
  transform-origin: 12px 12px;
  z-index: 1;
  &:focus {
    transform: rotate(-360deg);
  }
`;

export const DownloadExcelButton = styled(UnstyledButton)`
  position: absolute;
  right: 53px;
  z-index: 1;
  width: 35px;
  height: 35px;
  transform-origin: 16px 13.5px;
  fill: ${(props) => (props.disabled ? props.theme.colors.outline : props.theme.colors.primary)};
  &:hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const StyledSegmentGroup = styled(ToggleButtonGroup)`
  margin-right: 10px;
`;

const StyledSegmentButton = styled(ToggleButton)`
  width: 115px;
  margin-right: 0;
`;

const StyledControlBox = styled('div')`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 50%;
  bottom: 35px;
  transform: translate(-50%, 0);
  @media (max-width: 663px) {
    flex-direction: column;
    bottom: 0;
  }
`;

const StyledDatePicker = styled('div')`
  width: 230px;
  height: 38px;
  position: relative;
  & .DateRangePickerInput {
    display: flex;
    align-items: center;
  }
  & input {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.colors.text};
    border: 1px solid ${(props) => props.theme.colors.outline};
    border-radius: 4px;
    height: 46px;
    width: 100%;
    box-sizing: border-box;
    margin: 0px;
    appearance: none;
    padding: 3px 3px;
    text-align: center;
    cursor: pointer;
    &:focus {
      border-bottom: none;
    }
  }
  @media (max-height: 900px) {
    & .DateRangePicker_picker {
      background-color: transparent;
    }
    & .DayPicker_transitionContainer__vertical {
      height: 605px !important;
      margin-bottom: 130px;
    }
  }

  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const Close = styled('div')`
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translate(0, -50%);
  width: 15px;
  height: 15px;
  & svg {
    margin: 0;
    width: 100%;
    height: 100%;
    fill: ${(props) => props.theme.colors.outline};
  }
  :hover {
    cursor: pointer;
  }
  @media (max-width: ${(props) => props.theme.phone}) {
    width: 38px;
    height: 38px;
    right: -38px;
  }
`;

DashboardHeader.propTypes = {
  getOrders: PropTypes.func,
  onSwitchTabs: PropTypes.func,
  getOrdersForSpecificDays: PropTypes.func,
  onCalendarOrdersType: PropTypes.func,
  getDataForExcelExtraction: PropTypes.func,
  orders: PropTypes.arrayOf(PropTypes.any),
  excelExtractData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.func,
    ]),
  ),
  allowExcelDownload: PropTypes.bool,
  downloadExcel: PropTypes.func,
  exportExcelOrdersLoading: PropTypes.bool,
  excelExtractionError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  specificDays: PropTypes.objectOf(PropTypes.string),
  selectedLocation: PropTypes.string.isRequired,
};
DashboardHeader.defaultProps = {
  getOrders: () => {},
  onSwitchTabs: () => {},
  getOrdersForSpecificDays: () => {},
  onCalendarOrdersType: () => {},
  getDataForExcelExtraction: () => {},
  orders: [],
  excelExtractData: [],
  allowExcelDownload: false,
  downloadExcel: () => {},
  exportExcelOrdersLoading: false,
  excelExtractionError: false,
  specificDays: {},
};

export default DashboardHeader;
