import { Epic, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';

import { getMenuFromApi, getNutrition } from '../apis';
import { actions as menuActions } from '../reducers/menu';
import { selectLocationNumber, types as userTypes, actions as userActions } from '../reducers/user';
import epicHelper, { epicOptions } from '../util/myEpicHelper';
import type { State } from '../reducers';
import ooeConstants from '../constants';

export const GetPickupMenuEpic: Epic<
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof menuActions)['menuSuccess']>
  | ReturnType<(typeof menuActions)['menuFailure']>,
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof menuActions)['menuSuccess']>
  | ReturnType<(typeof menuActions)['menuFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.GET_USER_LOCATIONS_SUCCESS, userTypes.UPDATE_USER_LOCATION),
    switchMap(() => {
      const state = store.value;
      const location = selectLocationNumber(state);
      return epicHelper(
        getMenuFromApi(location, ooeConstants.DXE_CATERING_TYPE, ooeConstants.DXE_PICKUP_METHOD),
        menuActions.menuSuccess(ooeConstants.PICKUP),
        menuActions.menuFailure(ooeConstants.PICKUP),
        epicOptions(store, action$),
      );
    }),
  );

export const GetDeliveryMenuEpic: Epic<
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof menuActions)['menuSuccess']>
  | ReturnType<(typeof menuActions)['menuFailure']>,
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof menuActions)['menuSuccess']>
  | ReturnType<(typeof menuActions)['menuFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.GET_USER_LOCATIONS_SUCCESS, userTypes.UPDATE_USER_LOCATION),
    switchMap(() => {
      const state = store.value;
      const location = selectLocationNumber(state);
      return epicHelper(
        getMenuFromApi(location, ooeConstants.DXE_CATERING_TYPE, ooeConstants.DXE_DELIVERY_METHOD),
        menuActions.menuSuccess(ooeConstants.DELIVERY),
        menuActions.menuFailure(ooeConstants.DELIVERY),
        epicOptions(store, action$),
      );
    }),
  );

export const GetNutrition: Epic<
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof menuActions)['nutritionSuccess']>
  | ReturnType<(typeof menuActions)['nutritionFailure']>,
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof menuActions)['nutritionSuccess']>
  | ReturnType<(typeof menuActions)['nutritionFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.GET_USER_LOCATIONS_SUCCESS, userTypes.UPDATE_USER_LOCATION),
    switchMap(() =>
      epicHelper(
        getNutrition(),
        menuActions.nutritionSuccess,
        menuActions.nutritionFailure,
        epicOptions(store, action$),
      ),
    ),
  );

export default [
  GetPickupMenuEpic,
  GetDeliveryMenuEpic,
  // GetNutrition, // TODO: 401 for every request
];
