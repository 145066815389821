import { Flex } from '@cfacorp/cowponents';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { featureFlagVariation } from '../../../apis';
import { FavoriteOrder as FavoriteOrderComponent } from '../../../components/FavoriteOrder/FavoriteOrder';
import {
  actions as guestActions,
  selectCurrentFavoriteNames,
  selectFavoriteActionSuccessful,
  selectFavoritesError,
  selectFavoritesLoading,
  selectFavoriteSuggestedName,
  selectPastAndFavoriteOrders,
} from '../../../reducers/guest';
import { actions as orderActions } from '../../../reducers/order';
import Order from '../../../types/order';
import PastOrdersCard from '../PastOrdersCard';

interface PastOrdersProps {
  customer: GuestSearchResult;
}

export const PastOrders: React.FC<PastOrdersProps> = ({ customer }) => {
  const dispatch = useDispatch();
  const pastAndFavoriteOrders = useSelector(selectPastAndFavoriteOrders);
  const favoritesLoading = useSelector(selectFavoritesLoading);
  const favoritesError = useSelector(selectFavoritesError);
  const favoriteActionSuccessful = useSelector(selectFavoriteActionSuccessful);
  const suggestedFavoriteName = useSelector(selectFavoriteSuggestedName);
  const currentFavoriteNames = useSelector(selectCurrentFavoriteNames);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [orderId, setOrderId] = useState<string>();
  const [favoriteOrderId, setFavoriteOrderId] = useState<string>();
  const [favoriteName, setFavoriteName] = useState<string>();

  const handleAddToFavorites = (favName: string) =>
    orderId && dispatch(guestActions.addToFavorites(orderId, favName));
  const handleRemoveFromFavorites = () =>
    favoriteOrderId && dispatch(guestActions.removeFromFavorites(favoriteOrderId));
  const handleUpdateFavoriteName = (favName: string) =>
    favoriteOrderId && dispatch(guestActions.updateFavoriteName(favoriteOrderId, favName));

  const openModal = (id?: string, favOrderId?: string, name?: string) => {
    setModalIsOpen(true);
    setOrderId(id);
    setFavoriteOrderId(favOrderId);
    setFavoriteName(name);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    dispatch(guestActions.resetFavoriteActionErrors());
  };

  if (pastAndFavoriteOrders?.length === 0) {
    return null;
  }

  return (
    <Flex flexWrap="wrap" justifyContent={['center', 'end']} m="20px 0">
      {pastAndFavoriteOrders?.map((order, idx) => (
        <PastOrdersCard
          handleReorderClick={() => dispatch(orderActions.initiateEditOrder(order as Order, customer, true))}
          key={`${(order as Order).id}-${idx}`}
          openFavoriteModal={openModal}
          order={order}
          showReorderButton={() => featureFlagVariation('reorder')}
        />
      ))}
      {modalIsOpen && (
        <FavoriteOrderComponent
          addToFavorites={handleAddToFavorites}
          close={closeModal}
          currentFavoriteNames={currentFavoriteNames}
          favoriteActionSuccessful={favoriteActionSuccessful}
          favoritesError={favoritesError}
          favoritesLoading={!!favoritesLoading}
          isFavorite={favoriteOrderId !== undefined}
          isOpen={modalIsOpen}
          removeFromFavorites={handleRemoveFromFavorites}
          setFavoriteName={favoriteName}
          suggestedFavoriteName={suggestedFavoriteName}
          updateFavoriteName={handleUpdateFavoriteName}
        />
      )}
    </Flex>
  );
};

export default PastOrders;
